@strip-content-size-transition-duration: 300ms;
@strip-content-size-transition-delay: 100ms;
// .jw-is-strips is only applied if the template is configured to support strips.
// We try to make sure that the extra strip markup does not break the layout of other templates.
.jw-is-strips {
    .jw-strip-root {
        margin: 0 !important;
    }

    // Styling for a single strip.
    // Note: a strip is not necessarily a StripBrick (eg. the footer is also a strip).
    .jw-strip {
        width: 100%;
        box-sizing: border-box;
        padding: 0 var(--strip-content-padding-inline);

        &__content {
            width: 100%;
            position: relative; // make sure we can properly render the highlight in the Editor,
            // which is based on an absolutely positioned placeholder

            box-sizing: border-box;
            margin-right: auto;
            margin-left: auto;
            max-width: var(--simple-brick-max-width);

            // Used to animate sizing change in the editor
            transition: max-width @strip-content-size-transition-duration ease
                @strip-content-size-transition-delay;
        }

        &--padding-both {
            padding-top: var(--strip-content-padding-block);
            padding-bottom: var(--strip-content-padding-block);

            &.jw-strip--primary {
                padding-top: var(--strip-content-padding-block--primary);
                padding-bottom: var(--strip-content-padding-block--primary);
            }

            // If a strip is empty we don't want the padding, as that creates big white spaces
            //   if a strip contains a crumb-trail, it does get the '--empty' class, but it should keep padding
            //   to display the breadcrumbs properly
            &:not(:has(.jw-strip__content *)),
                // So select all empty strips, but not if it has a placeholder
            &:has(.jw-strip__content.jw-tree-container--empty):not(:has(.jw-tree-empty-placeholder)) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &--padding-start {
            padding-top: var(--strip-content-padding-block);

            &.jw-strip--primary {
                padding-top: var(--strip-content-padding-block--primary);
            }
        }

        &--padding-end {
            padding-bottom: var(--strip-content-padding-block);

            &.jw-strip--primary {
                padding-bottom: var(--strip-content-padding-block--primary);
            }
        }
    }
}

.jw-strip--draggable {
    // Prevent selecting page content when dragging a strip
    // Note: this does mean that you can't deselect text by clicking on the strip, but that's a minor issue
    user-select: none;

    .jw-strip__content {
        user-select: initial;
    }
}

.jw-strip__width-hint {
    // no clickly clicky
    pointer-events: none;

    // Size the same as .jw-strip__content
    max-width: var(--simple-brick-max-width);
    width: 100%;

    // Center on the strip content
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    // Hidden by default
    opacity: 0;

    transition: max-width @strip-content-size-transition-duration ease
        @strip-content-size-transition-delay;

    &--fade {
        animation: stripWidthHintFadeOut 3s;
        animation-fill-mode: forwards;
    }

    &::before,
    &::after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        width: calc(50vw - (var(--simple-brick-max-width) / 2));

        background-color: fade(@brand-primary, 50%);

        transition: ease all;
        transition-duration: @strip-content-size-transition-duration;
        transition-delay: @strip-content-size-transition-delay;
    }
    &::before {
        border-right: 2px solid fade(@brand-primary, 50%);
        right: 100%;
    }
    &::after {
        border-left: 2px solid fade(@brand-primary, 50%);
        left: 100%;
    }
}

@keyframes stripWidthHintFadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
