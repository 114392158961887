.jw-element-ads {
    position: relative;

    // spacing is automatically taken care of when a template supports strips. We need to
    // manually add it for legacy templates
    // TODO#strips remove once strips are supported everywhere
    body:not(.jw-is-strips) & {
        margin-top: 30px;
    }

    h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;

        a {
            display: inline-flex;
            margin: 0 0.3em;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &:focus,
            &:hover {
                &::after {
                    background: hsla(0, 0%, 100%, 10%);
                }
            }
        }
    }
}

.jw-section {
    .jw-element-ads h3 {
        margin: 15px 5px;
    }

    // Variations
    .jw-element-ads--logo,
    .jw-element-ads--accent,
    .jw-element-ads--tint {
        h3 a {
            color: currentColor;
            text-decoration: underline;

            &:hover {
                color: currentColor;
            }
        }
    }

    .jw-element-ads--logo {
        h3 {
            margin: 0;

            svg {
                margin: 10px 0;
            }
        }
    }
}
