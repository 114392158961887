@jw-rating-sizes: 16, 24, 32;
@jw-rating-colors: {
    yellow: #f5ce02;
};

@star-size: 1em;
@star-gap: 0.1em;

each(@jw-rating-colors, {
    .jw-element-rating--color-@{key} {
        .jw-element-rating__icon-full {
            color: @value;
        }
    }
})
    .jw-element-rating__stars {
    display: inline-flex;
    flex-direction: row;
    // Allow stars to wrap, so the rating element won't exceed its allocated width
    flex-wrap: wrap;

    line-height: @star-size;
    margin: 0 -(@star-gap / 2);

    each(@jw-rating-sizes, {
        .jw-element-rating--size-@{value} & {
            font-size: @value * 1px;
        }
    });

    .jw-element-rating--voting-enabled &:focus-within {
        .default-focus-outline();
    }
}

.jw-element-rating__star {
    width: @star-size;
    height: @star-size;
    padding: 0 (@star-gap / 2);
    overflow: hidden;

    cursor: default;

    .jw-element-rating__icon-full,
    .jw-element-rating__icon-empty {
        width: @star-size;
        height: @star-size;
        display: inline-block;
        position: relative;
        overflow: hidden;

        &::before {
            margin: 0;
        }
    }

    .jw-element-rating__icon-empty {
        // Make sure the right half of the star is visible
        direction: rtl;
    }

    // Static values (when user is not interacting)
    &--empty {
        .jw-element-rating__icon-full {
            display: none;
        }
    }

    &--half {
        .jw-element-rating__icon-full,
        .jw-element-rating__icon-empty {
            width: 0.5em;
        }
    }

    &--full {
        .jw-element-rating__icon-empty {
            display: none;
        }
    }

    // Dynamic values (when user is interacting)
    .jw-element-rating--voting-enabled .jw-element-rating__stars:hover &,
    .jw-element-rating--voting-enabled
        .jw-element-rating__stars:focus-within
        & {
        .jw-element-rating__icon-full {
            display: inline-block;
            width: @star-size;
        }

        .jw-element-rating__icon-empty {
            display: none;
            width: @star-size;
        }
    }

    .jw-element-rating--voting-enabled .jw-element-rating__stars:hover &:hover,
    .jw-element-rating--voting-enabled
        .jw-element-rating__stars:focus-within
        input:focus
        + & {
        & ~ .jw-element-rating__star {
            .jw-element-rating__icon-full {
                display: none;
            }
            .jw-element-rating__icon-empty {
                display: inline-block;
            }
        }
    }
}

.jw-element-rating__text {
    display: block;
}
