@import (reference) '../layout.less';

.jw-element-imagetext-container {
    // Make sure first text node has no top margin if this element has an image.
    &--image {
        .clearfix();

        .jw-element-imagetext-text *:first-child {
            margin-top: 0;
        }
    }
}

// TODO#strips: Make this work with strips
// Remove margin-top from first text node in the first element of a section.
// On both Desktop and Mobile.
.jw-section .jw-simple-root > .jw-image-text.jw-node-is-first-child {
    .jw-element-imagetext-text *:first-child {
        margin-top: 0;
    }
}

.jw-image-text {
    // Remove margin-top from first text node in the first element of a container, and
    // margin-bottom from the last element of a container.
    &.jw-node-is-first-child {
        .jw-element-imagetext-text *:first-child {
            margin-top: 0;
        }
    }
    &.jw-node-is-last-child {
        .jw-element-imagetext-text *:last-child {
            margin-bottom: 0;
        }
    }
}

.jw-element-imagetext-text {
    min-width: 4em;
}

.jw-image-text {
    .jw-element-image-is-right {
        margin-left: @element-gap-block;
    }
    .jw-element-image-is-left {
        margin-right: @element-gap-block;
    }
    .jw-element-image {
        margin-bottom: @element-gap-block;
    }
}
