@import (reference) '../layout.less';

.jw-element-spacer-label {
    opacity: 0;
}

.jw-element-spacer-backend {
    outline: dashed 1px rgba(0, 0, 0, 0.1);
    outline-offset: -1px;
    min-height: 10px;

    .jw-element-spacer-label {
        height: 1em;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        line-height: 1;
    }
}

.jw-section-item--dark {
    .jw-element-spacer-backend {
        outline: dashed 1px rgba(255, 255, 255, 0.2);

        .jw-element-spacer-label {
            color: rgba(255, 255, 255, 0.3);
        }
    }
}

// In Editor: show spacer label on hover or during resizing
.jw-element-spacer-container.jw-element-spacer-backend {
    transition:
        background-color 0.5s,
        border-color 0.5s;

    &.jw-element-spacer-container--resizing,
    body:not(.is-picking) .jw-spacer:hover &.ui-resizable {
        background-color: rgba(130, 130, 130, 0.2);
        border-color: rgba(130, 130, 130, 0.3);

        .jw-element-spacer-label {
            transition: opacity 0.5s;
            opacity: 1;
        }
    }
}

// hide spacer when columns collapsed
.jw-spacer .jw-element-spacer-container {
    .jw-layout-collapsed({
        max-height: 10px;
    });
}
