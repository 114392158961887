.message-bar {
    text-align: center;
    max-width: 100vw;

    a {
        color: inherit;
        text-decoration: underline;
    }

    .jw-is-backend-loaded &:hover,
    .jw-is-backend-loaded &--selected {
        cursor: pointer;
        box-shadow: inset 0 0 0 1px @brand-primary;
    }
}

.message-bar-usps {
    display: flex;
    justify-content: center;

    margin: 0;
    padding: 0;
    overflow: hidden;

    list-style: none;

    // Show only the first item while the slider is loading
    &--slider-loading {
        .message-bar-usps__item:not(:first-child) {
            display: none;
        }
    }

    &__item {
        flex: 0 0 auto;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        box-sizing: border-box;
        max-width: 100%;

        // Items with one line will have an effective top/bottom-padding of .5em,
        // due to the min-height set on .message-bar-usps in message-bar.template.less.
        // Items with multiple lines will get a top/bottom-padding of .25em.
        padding: 0.25em 0.5em;

        @media (min-width: 640px) {
            padding: 0.5em 1em;
        }

        [class^='website-rendering-icon'] {
            margin-right: 0.25em;
        }
    }

    // Make message bar wrap on the settings page, so all items are visible
    #feature-bar-preview & {
        flex-wrap: wrap;
    }
}

.message-bar-text {
    margin: 0;
    padding: 0.5em;
}
