@import (reference) '../layout.less';

.jw-image {
    .clearfix();
}

.jw-element-image {
    position: relative;
    max-width: 100%;
    // Width is set inline on this element
    line-height: 0;
    user-select: none;
}

.jw-element-image__image-wrapper {
    width: 100%;
}

.jw-element-image__image {
    width: 100%;
}

.jw-element-image-is-right {
    float: right;
}
.jw-element-image-is-left {
    float: left;
}
.jw-element-image-is-center {
    margin: 0 auto;
}

// Center images on small devices
.jw-element-image,
.jw-image-text .jw-element-image {
    .jw-layout-collapsed({
        float: none;
        margin-left: auto;
        margin-right: auto;

        // Set custom property so the Editor can check if the image was forcibly centered
        --jw-image-centered: 1;
    });
}

.jw-element-image--full-width-to(@breakpoint) {
    @value: unit(@breakpoint);
    .jw-element-image--full-width-to-@{value} {
        @media (max-width: @breakpoint) {
            width: 100% !important;
        }
    }
}

.jw-element-image--full-width-to(640px);
.jw-element-image--full-width-to(420px);

// Icon images
.jw-icon-image {
    &__inner {
        display: block;
        width: 100%;
        height: 100%;

        mask-size: 100%;
        mask-position: center;
        mask-repeat: no-repeat;

        // mask-image: <set via inline styles>;
        // background-color: <set via template styles>;
    }
}

.jw-image-is-square,
.jw-image-is-rounded,
.jw-image-is-round {
    .jw-icon-image {
        // background-color: <set via template styles>;

        &__inner {
            mask-size: 50%;
            // background-color: <set via template styles>;
        }
    }
}

// Intrinsic ratio
.jw-intrinsic {
    display: block;

    position: relative;
    height: 0;
    width: 100%;
    // padding-top: <aspect ratio>; is set in HTML

    & .jw-intrinsic__item {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        // items below are necessary to work around an apparent IntersectionObserver bug in
        // Chrome (https://github.com/Webador/jouwweb/issues/5185)
        width: 100%;
        height: 100%;
    }
}

/* EDITOR */
.jw-is-backend .jw-element-image {
    cursor: pointer;
}

.jw-element-image__loader {
    display: none;
}

.jw-element-image--loading {
    overflow: hidden;

    .jw-element-image__image {
        opacity: 0.8;
        filter: blur(10px);
    }

    .jw-element-image__loader {
        display: block;
        width: 70px;
        height: 18px;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        margin: auto;
    }
}

div.jw-element-image__pan {
    position: absolute;

    .rangeslider {
        position: absolute;
    }

    &--vertical {
        top: 0;
        right: 30px;
        height: 100%;

        .rangeslider {
            height: auto;
            top: 15px;
            bottom: 15px;

            .jw-is-touch-device & {
                // Prevent overlap with element context buttons
                top: 30px;
            }
        }
    }

    &--horizontal {
        left: 0;
        bottom: 30px;
        width: 100%;

        .rangeslider {
            width: auto;
            left: 15px;
            right: 15px;
        }
    }
}
