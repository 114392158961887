// Layout of account pages. Navigation is displayed left of page when there is enough space for it.

.account-page {
    display: flex;

    .lt800 & {
        flex-direction: column;
    }
}

.account-page__navigation {
    flex: 0 0 200px;

    .lt800 & {
        order: 1;
    }
}

.account-page__content {
    flex: 1 1 auto;
}
.account-current-login {
    float: right;
    margin-top: 1.3em;
}

.account-navigation__title,
.jw-section .account-navigation__title {
    margin-top: 1.3em;
}

.account-navigation__item {
    padding: 0.15em 0;

    &--active {
        font-weight: bold;
    }
}
