@import '../../backend/base/mixins';

@jw-menu-add-size: 30px;
@jw-menu-add-margin: 5px;

.jw-menu-add {
    display: none;
    position: absolute;
    z-index: 1;

    width: @jw-menu-add-size;
    height: @jw-menu-add-size;
    top: 50%;
    margin-top: -(@jw-menu-add-size / 2);
    right: -@jw-menu-add-size - @jw-menu-add-margin;
}

.jw-menu-vertical .jw-menu-add {
    top: 100%;
    margin-top: @jw-menu-add-margin;
    right: auto;
}

.jw-is-touch-device {
    .jw-menu-add {
        display: none;
    }
}

.jw-menu-cart-item,
.jw-mobile-cart {
    display: none;
}

.jw-menu-cart-item--hidden {
    margin: 0 !important;
    display: none !important;

    .jw-popover-container {
        visibility: hidden;
        z-index: -1;
        opacity: 0;
    }

    .jw-menu-link,
    .jw-popover-backdrop,
    .jw-popover {
        display: none !important;
    }
}

.jw-mobile-header-cart--hidden .jw-icon-badge-wrapper {
    visibility: hidden;
}

.jw-menu-is-desktop {
    .jw-menu-cart-item {
        display: block;
    }
}

.jw-menu-is-mobile {
    .jw-mobile-cart {
        display: block;
        margin-bottom: 1em;
        position: relative;
    }
}

.jw-is-android.jw-menu-is-desktop {
    .jw-arrow {
        font-size: 0.6em;
        padding-top: 0.4em;
    }

    .jw-submenu .jw-arrow:after {
        content: '►';
    }

    .jw-menu-horizontal .jw-arrow-toplevel:after {
        content: '▼';
    }

    .jw-menu-vertical .jw-arrow-toplevel:after {
        content: '►';
    }
}

// Inverted to hide titles by default (to not have them jump around)
// Otherwise ".jw-menu-is-desktop .jw-menu-horizontal &" would suffice
.hidden-desktop-horizontal-menu {
    display: none;

    .jw-menu-is-mobile &,
    .jw-menu-vertical & {
        display: inline;
    }
}

.jw-menu-wishlist-item--hidden {
    display: none !important;
}
