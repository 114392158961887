.jw-steps {
    margin-bottom: 1em;

    ol {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        flex: 1 0 1px; // 1px instead of 0 to prevent IE11 from not parsing the property altogether;
        display: flex;
    }
}

.jw-steps__step {
    @step-height: 3em;
    @step-arrow-height: 1.2em;

    display: flex;
    align-items: center;
    justify-content: center;

    height: @step-height;
    flex-basis: 100%;
    padding: 0 1em;
    position: relative;
    box-sizing: border-box;

    border-style: solid;
    border-width: 1px 1px 1px 0;
    text-decoration: none;

    a&:hover,
    a&:focus {
        text-decoration: underline;
    }

    &--selected {
        font-weight: bold;
    }

    li:first-child > & {
        border-left-width: 1px;

        border-radius: 0.2em 0 0 0.2em;
    }

    li:last-child > & {
        border-radius: 0 0.2em 0.2em 0;

        &::before,
        &::after {
            display: none;
        }
    }

    // ::before for the arrow
    // ::after to cover-up 1-pixel-offset glitches
    &::before,
    &::after {
        content: '';
        display: block;

        position: absolute;
        z-index: 1;
        right: 0;

        background-color: inherit;
    }

    &::before {
        top: 50%;
        transform: translate(50%, -50%) rotate(-45deg);

        width: @step-arrow-height * sin(45deg);
        height: @step-arrow-height * sin(45deg);
        box-sizing: border-box;

        border-color: inherit;
        border-style: solid;
        border-width: 0 1px 1px 0;
    }

    &::after {
        z-index: 2;
        top: 0;
        width: 1px;
        height: 100%;
    }
}

.jw-steps__icon {
    margin-right: 0.2em;
}
