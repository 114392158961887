@import (reference) '../layout.less';

@columns-spacing: 24px;
@columns-separator-width: 2px;

.jw-tree-horizontal {
    .jw-layout-full({
        display: flex;
        flex-direction: row;
        align-items: stretch;

        width: auto !important;

        margin-left: (@columns-spacing / -2);
        margin-right: (@columns-spacing / -2);

        > .jw-tree-node {
            min-width: 0; // Otherwise Firefox will overflow its parent

            padding-left: (@columns-spacing / 2);
            padding-right: (@columns-spacing / 2);
        }

        // Separators
        > .jw-columns__node--separator-left {
            &.jw-node-is-first-child {
                margin-left: (@columns-spacing / 2);
            }

            border-left-style: solid;
            border-left-width: @columns-separator-width;
        }

        > .jw-columns__node--separator-right {
            &.jw-node-is-last-child {
                margin-right: (@columns-spacing / 2);
            }

            border-right-style: solid;
            border-right-width: @columns-separator-width;
        }
    });

    .jw-layout-collapsed({
        // Set custom variable so that Editor can pick up on it
        // (eg. to prevent adding columns when the layout is collapsed)
        --jw-columns-collapsed: 1;
    });
}

.jw-column {
    // Collapse columns on mobile phones
    .jw-layout-collapsed({
        width: 100% !important;
        display: block !important;

        .jw-tree-empty-placeholder {
            display: none;
        }
    });
}

.jw-is-backend {
    .jw-columns__separator-remove-wrap {
        display: none;

        position: absolute;
        top: 0;
        z-index: @zindex-jw-context-buttons - 1;

        &--left {
            left: -9px;
        }
        &--right {
            right: -9px;
        }
    }

    .jw-tree-horizontal:hover {
        .jw-columns__node--separator-left
            .jw-columns__separator-remove-wrap--left,
        .jw-columns__node--separator-right
            .jw-columns__separator-remove-wrap--right {
            display: flex;
        }
    }
}

.jw-columns--is-removing-child {
    & > .jw-tree-node:not(.jw-columns__node--is-removing) {
        // With smaller values for flex-grow the remaining columns sometimes
        // would not fill the entire container, so we multiply it by 100.
        flex-grow: calc(var(--column-weight) * 100);
    }
}
