@font-face {
  font-family: 'website-rendering';
  src: url('../font/website-rendering.eot?84228985');
  src: url('../font/website-rendering.eot?84228985#iefix') format('embedded-opentype'),
       url('../font/website-rendering.woff2?84228985') format('woff2'),
       url('../font/website-rendering.woff?84228985') format('woff'),
       url('../font/website-rendering.ttf?84228985') format('truetype'),
       url('../font/website-rendering.svg?84228985#website-rendering') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'website-rendering';
    src: url('../font/website-rendering.svg?84228985#website-rendering') format('svg');
  }
}
*/
[class^="website-rendering-icon-"]:before, [class*=" website-rendering-icon-"]:before {
  font-family: "website-rendering";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.website-rendering-icon-twitter:before { content: '\e800'; } /* '' */
.website-rendering-icon-facebook:before { content: '\e801'; } /* '' */
.website-rendering-icon-star:before { content: '\e802'; } /* '' */
.website-rendering-icon-pinterest:before { content: '\e803'; } /* '' */
.website-rendering-icon-linkedin:before { content: '\e804'; } /* '' */
.website-rendering-icon-help-circled:before { content: '\e805'; } /* '' */
.website-rendering-icon-youtube:before { content: '\e806'; } /* '' */
.website-rendering-icon-tumblr:before { content: '\e807'; } /* '' */
.website-rendering-icon-location:before { content: '\e808'; } /* '' */
.website-rendering-icon-mail:before { content: '\e809'; } /* '' */
.website-rendering-icon-basket:before { content: '\e80a'; } /* '' */
.website-rendering-icon-phone:before { content: '\e80b'; } /* '' */
.website-rendering-icon-whatsapp:before { content: '\e80c'; } /* '' */
.website-rendering-icon-left-open-big:before { content: '\e80d'; } /* '' */
.website-rendering-icon-right-open-big:before { content: '\e80e'; } /* '' */
.website-rendering-icon-search:before { content: '\e80f'; } /* '' */
.website-rendering-icon-cancel:before { content: '\e810'; } /* '' */
.website-rendering-icon-down-open-big:before { content: '\e811'; } /* '' */
.website-rendering-icon-truck:before { content: '\e812'; } /* '' */
.website-rendering-icon-credit-card:before { content: '\e813'; } /* '' */
.website-rendering-icon-ok:before { content: '\e814'; } /* '' */
.website-rendering-icon-google:before { content: '\e815'; } /* '' */
.website-rendering-icon-star-empty:before { content: '\e816'; } /* '' */
.website-rendering-icon-tiktok:before { content: '\e817'; } /* '' */
.website-rendering-icon-tiktok-thin:before { content: '\e818'; } /* '' */
.website-rendering-icon-whatsapp-thin:before { content: '\e819'; } /* '' */
.website-rendering-icon-heart:before { content: '\e81a'; } /* '' */
.website-rendering-icon-heart-empty:before { content: '\e81b'; } /* '' */
.website-rendering-icon-facebook-thin:before { content: '\e81c'; } /* '' */
.website-rendering-icon-instagram-thin:before { content: '\e81d'; } /* '' */
.website-rendering-icon-linkedin-thin:before { content: '\e81e'; } /* '' */
.website-rendering-icon-pinterest-thin:before { content: '\e81f'; } /* '' */
.website-rendering-icon-tumblr-thin:before { content: '\e820'; } /* '' */
.website-rendering-icon-twitter-thin:before { content: '\e821'; } /* '' */
.website-rendering-icon-youtube-thin:before { content: '\e822'; } /* '' */
.website-rendering-icon-x-logo:before { content: '\e823'; } /* '' */
.website-rendering-icon-discord:before { content: '\e82e'; } /* '' */
.website-rendering-icon-download:before { content: '\e831'; } /* '' */
.website-rendering-icon-left-open:before { content: '\e84a'; } /* '' */
.website-rendering-icon-right-open:before { content: '\e84b'; } /* '' */
.website-rendering-icon-user:before { content: '\e872'; } /* '' */
.website-rendering-icon-reply:before { content: '\f02a'; } /* '' */
.website-rendering-icon-cancel-circled:before { content: '\f06e'; } /* '' */
.website-rendering-icon-info-circled:before { content: '\f085'; } /* '' */
.website-rendering-icon-instagram:before { content: '\f16d'; } /* '' */
.website-rendering-icon-id-card-o:before { content: '\f2c3'; } /* '' */
.website-rendering-icon-telegram:before { content: '\f2c6'; } /* '' */
