/*
    1. Position the flags above the next elements in DOM. In combination with the slideshow element,
       the flags would otherwise not be ‘clickable’.
*/
.jw-flags {
    position: relative; // 1
    z-index: 1; // 1
    float: right;
    // todo: check impact of thi with changed margins
    margin-bottom: 1em;
    margin-top: -1.5rem;
}

// Additonal pat
.flag-icon,
.flag-icon:hover {
    text-decoration: none !important;
}

.flag-icon.flag-icon--clickable {
    transition: transform 200ms ease-out 0s;
    &:hover {
        transform: translate(0px, -3px);
    }
}
