@import (reference) '../layout.less';

.jw-issuer {
    margin-left: 0.5em;
    visibility: hidden;
}

.jw-toggle-block__control:checked ~ .jw-toggle-block__label .jw-issuer {
    visibility: visible;
}

.jw-toggle-block__button {
    // Use `visibility` so space is reserved.
    visibility: hidden;

    // Except on smaller screens where the issuer input needs all the space it can get.
    .jw-layout-collapsed({
        display: none;
    });
}

// Show next button for:
// - Every payment method without an issuer (like PayPal)
// - A payment method with a selected issuer (like iDeal when a bank is selected)
.jw-toggle-block__control:checked ~ .jw-toggle-block__label {
    .jw-toggle-block__title + .jw-toggle-block__button,
    .jw-issuer--has-value + .jw-toggle-block__button {
        .jw-layout-full({
            visibility: visible;
        });
    }
}
