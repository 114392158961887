.jw-html-wrapper {
    position: relative;
    z-index: 1; // prevent z-index issues with embed/HTML content by not letting
    // it 'escape' its stacking context
}

.jw-html-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0; // not technically necessary, but forces a new stacking context,
    // meaning overlay will always be on top, regardless of content
    // z-index settings
}

.jw-embed-placeholder {
    opacity: 0.4;
}

.jw-html-wrapper,
.jw-html-wrapper * {
    max-width: 100%;
}
