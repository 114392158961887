.jw-contact-form {
    form {
        position: relative; // force stacking order
        // TODO: this is hacky, but it's needed to prevent jw-context from
        // overlaying this element, making any interaction impossible
    }
}

// Disable interaction with Captcha in editor.
.jw-is-backend .captcha-form-group {
    pointer-events: none;
}
