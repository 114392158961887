// Player alignment
.jw-element-video,
.jw-element-audio {
    &--align-left {
        margin: 0 auto 0 0;

        .ui-resizable-sw {
            display: none !important;
        }
    }
    &--align-center {
        margin: 0 auto;
    }
    &--align-right {
        margin: 0 0 0 auto;

        .ui-resizable-se {
            display: none !important;
        }
    }
}

// Video element
.jw-element-video {
    position: relative;
    max-width: 100%;

    &--fixed-ratio {
        &::before {
            content: '';
            display: block;
            padding-top: (100% / 16) * 9;
        }

        .jw-element-video__player {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            & > * {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__player {
        // Disable interaction with player while resizing
        .jw-is-backend .ui-resizable-resizing & {
            &,
            & > * {
                pointer-events: none;
            }
        }

        // Full-width placeholder/fallback video
        & > video {
            width: 100%;
        }
    }
}

// Audio element
.jw-element-audio {
    max-width: 640px;

    .plyr__controls {
        border: 1px solid;
    }

    & > audio {
        display: none;
    }
}

.lt200 .jw-element-audio {
    .plyr__time {
        display: none;
    }
}

.lt300 .jw-element-audio {
    .plyr {
        min-width: 0;
    }

    .plyr__menu,
    .plyr__volume {
        display: none;
    }
}
