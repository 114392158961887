#jw {
    &.jw-header-toggler {
        position: absolute;
        bottom: -5px;
        z-index: @zindex-jw-floater;

        .btn-group {
            white-space: nowrap;
            .clearfix();
        }
        .btn-group > .btn {
            float: none;
        }
    }

    .jw-header-button {
        position: absolute;
        display: none;

        &--desktop {
            top: -10px;
            right: -10px;
        }

        &--mobile {
            top: 0;
            margin-left: -10px;
        }

        .jw-element-is-active & {
            display: block;
        }
    }

    .jw-header-loader {
        display: none;

        .jw-element-is-loading & {
            display: flex;
        }
    }
}

.jw-header-title {
    outline: none;
}

.jw-header-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    max-width: 100%;
}

.jw-header-image-toggle > a {
    display: block;
    width: 100%;
}
