.image-gallery {
    display: grid;
}

.image-gallery__thumbnail-item,
.image-gallery__slide-item {
    display: flex;
}

.image-gallery__thumbnail-image,
.image-gallery__slide-image {
    position: relative;
    width: 100%;

    img {
        display: block;
        object-fit: contain;
        object-fit: scale-down;
    }
}

// More than one item: square slides and square thumbnails
.image-gallery__thumbnail-image,
.image-gallery:not(.image-gallery--single-item) .image-gallery__slide-image {
    padding-top: 100%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        height: 100%;
    }
}

// Single item: original aspect ratio of image (with a max-height)
.image-gallery--single-item .image-gallery__slide-image {
    max-height: 720px;

    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        margin: 0 auto;
    }
}

.image-gallery__main {
    position: relative;
    min-width: 0;
}

.image-gallery__slides {
    display: flex;
    overflow: hidden;
}

.image-gallery__slide-container {
    flex: 0 0 auto;
    width: 100%;
}

.image-gallery__slide-item {
    cursor: zoom-in;
}

.image-gallery__control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    // Reset button styles
    appearance: none;
    background: none;
    border: none;
    padding: 0;

    width: 2em;
    height: 2em;
    font-size: 20px;
    text-align: center;

    border-radius: 2em;
    background-color: rgba(black, 0.5);
    color: white;

    &--disabled {
        visibility: hidden;
    }

    &:hover,
    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 1.5px rgba(white, 0.6), 0 0 8px 1.5px rgba(black, 0.6);
        background-color: rgba(black, 0.65);
    }

    &--prev {
        left: 0.5em;
        padding-right: 0.1em;
    }
    &--next {
        right: 0.5em;
        padding-left: 0.1em;
    }
}

.image-gallery__thumbnails {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));

    margin-top: 8px;
    gap: 8px;
}

.image-gallery__thumbnail-item {
    padding: 2px;
    border: 1px solid;
}
