.jw-element-accordion {
    .jw-is-editor & {
        user-select: none;
    }

    .jw-element-accordion__item {
        display: block;
        outline: none;
        position: relative;
        cursor: pointer;
    }

    .jw-element-accordion__heading {
        position: relative;
        padding: 1.2rem 0;

        // Hide default marker for details > summary element
        list-style: none;
        &::marker, /* Latest Chrome, Edge, Firefox */
        &::-webkit-details-marker /* Safari */ {
            display: none;
        }

        h2,
        h3,
        h4 {
            margin: 0 !important;
            padding: 0 !important;
            color: inherit !important;
            background: none;
        }

        h4 {
            font-weight: bold !important;
        }
    }

    // Animation styles
    .jw-element-accordion__content--open-start {
        max-height: 0;
        overflow: hidden;
    }
    .jw-element-accordion__content--open-active {
        transition: max-height 200ms ease-out;
        max-height: var(--computedHeight);
    }

    .jw-element-accordion__content--close-start {
        max-height: var(--computedHeight);
    }
    .jw-element-accordion__content--close-active {
        transition: max-height 200ms ease-out;
        max-height: 0;
        overflow: hidden;
    }

    // Styling for icon alignment
    &--align-icon-right {
        .jw-element-accordion__heading {
            padding-right: 4rem !important;
        }
        .jw-element-accordion__icon {
            right: 0.8rem;
        }
    }
    &--align-icon-left {
        .jw-element-accordion__heading {
            padding-left: 2.5rem !important;
        }
        .jw-element-accordion__icon {
            left: -7px;
        }
    }

    // styling for icon
    .jw-element-accordion__icon {
        font-size: 1.8rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(0);
        transition: 250ms transform ease;

        &.website-rendering-icon-right-open {
            transform: translateY(-50%) rotate(90deg);
        }

        &--plus {
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0 0.4em;

            &::before,
            &::after {
                content: '';
                position: absolute;
                background-color: currentColor;
                transition: transform 0.25s ease-out;
                border-radius: 2px;
            }
            &::before {
                top: 0;
                left: 50%;
                width: 2px;
                height: 100%;
                margin-left: -1px;
            }
            &::after {
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                margin-top: -1px;
            }
        }
    }

    .jw-element-accordion__item[open]
        .jw-element-accordion__icon.website-rendering-icon-right-open {
        transform: translateY(-50%) rotate(-90deg);
    }

    .jw-element-accordion__item[open] .jw-element-accordion__icon--plus {
        &:before {
            transform: rotate(90deg);
        }
        &:after {
            transform: rotate(180deg);
        }
    }

    .jw-element-accordion__content-wrap {
        padding: 1rem 0;
    }

    // Styling for different box styles
    &--style-border {
        .jw-element-accordion__item {
            border-bottom: 1px solid;
        }
    }

    &--style-box {
        border-radius: 3px;
        overflow: hidden;

        .jw-element-accordion__heading {
            border-bottom: 1px solid;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .jw-element-accordion__content {
            border: 1px solid;
            border-top: none;
        }

        .jw-element-accordion__content-wrap {
            padding: 2rem 3.5rem;
        }

        &.jw-element-accordion--align-icon-left {
            .jw-element-accordion__heading {
                padding-left: 4rem !important;
            }
            .jw-element-accordion__icon {
                left: 1rem;
            }
        }
    }
}
