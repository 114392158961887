.hidden {
    display: none !important;
}

.make-visibility-classes(@threshold) {
    .visible-lt@{threshold} {
        &-block,
        &-inline-block,
        &-inline {
            display: none !important;
        }
    }
    .lt@{threshold} .visible-lt@{threshold} {
        &-block {
            display: block !important;
        }
        &-inline-block {
            display: inline-block !important;
        }
        &-inline {
            display: inline !important;
        }
    }
    .lt@{threshold} .hidden-lt@{threshold} {
        display: none !important;
    }
}

.for-each-threshold(@thresholds, @index: 1) {
    @threshold: extract(@thresholds, @index);
    .make-visibility-classes(@threshold);

    & when (@index <= length(@thresholds)) {
        .for-each-threshold(@thresholds, @index + 1);
    }
}

@thresholds: 200, 300, 400, 480, 540, 600, 800;
.for-each-threshold(@thresholds);

.jw-is-shown {
    display: block !important;
}
.jw-is-clickable {
    cursor: pointer !important;
}

.jw-is-touch-device {
    .hidden-touch {
        display: none !important;
    }
}
