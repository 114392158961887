.cart-item {
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &:first-child {
        border-top-width: 1px;
        border-top-style: solid;
    }
}

.cart-item__inner {
    display: flex;
    align-items: center;
    padding: 1em 0;
    margin: 0 -1em;

    .lt600 & {
        display: block;
        text-align: right;
        margin: 0;

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }
}

.cart-item__image {
    flex: 0 1 auto;
    margin: 0 0 0 1em;
    box-sizing: border-box;

    img {
        width: 60px;
        float: left;
        max-width: 100%;
        max-height: 100px;
        object-fit: contain;
    }

    .lt600 & {
        float: left;
        width: (100% / 6) * 1;
        max-width: 60px;
        padding: 0;
        margin: 0;
    }
}

.cart-item__image--placeholder {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 60px;
    width: 60px;

    img {
        width: 45px;
        height: 45px;
    }
}

.cart-item__details,
.cart-item__actions {
    margin: 0.5em 0;
    padding: 0 1em;
    box-sizing: border-box;

    .lt600 & {
        display: inline-block;
        width: (100% / 6) * 5;
        margin: 0;
        padding: 0 0 0 1em;

        text-align: left;
    }
}

.cart-item__details {
    flex: 1 1 50%;
    min-width: 0;
}

.cart-item__title {
    font-size: 1.2em;
    line-height: 1.2;
    overflow-wrap: break-word;
}

.cart-item__actions {
    flex: 0 0 40%;
    min-width: 240px;

    .lt600 & {
        min-width: 0;

        .product-quantity-input {
            margin-top: 0.5em;
        }
    }
}

.cart-item__amount-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
}

.cart-item__amount-label {
    .lt600 & {
        display: none;
    }
}

.cart-item__price {
    flex: 1 0 auto;
    text-align: right;
    margin-left: 0.5em;
}

.product-quantity-input {
    display: block;
    margin-bottom: 0.5em;

    &--block .product-quantity-input__label {
        display: block;
    }
}

.product-quantity-input__select,
.product-quantity-input__input {
    width: 70px !important;
    margin-left: 0.2em;
}

.product-quantity-input__remove {
    font-size: 1.2em;
}
