.jw-element-sitemap-is-hiding {
    .jw-element-sitemap-hidden {
        display: none;
    }
}

/**
 * Make sure sitemap brick is interactable, even when there are no visible pages.
 * https://github.com/Webador/jouwweb/issues/12464
 */
.jw-intent {
    .jw-element-sitemap-is-invisible {
        min-height: 1.5rem;
    }

    // only apply min-height to root list
    .jw-element-sitemap-list .jw-element-sitemap-is-invisible {
        display: none;
    }
}
