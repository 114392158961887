@import (reference) '../layout.less';

.jw-element-separator-padding {
    // Contain the separator margins
    display: flex;
    flex-direction: column;
}

.jw-element-separator {
    // Fallback value; overridden through inline styles with the user's preference
    --jw-element-separator__margin: 2em;
    margin: var(--jw-element-separator__margin) 0;

    background-color: transparent;
    border: 0 solid;

    .separatorSizes(1);

    &--dotted {
        border-style: dotted;
    }
    &--dashed {
        border-style: dashed;
    }
    &--double {
        border-style: double;

        // Multiply size of double borders to have the lines equal the regular size
        .separatorSizes(3);
    }

    .separatorSizes(@size-multiplier) {
        &.jw-element-separator {
            border-top-width: 1px * @size-multiplier;

            &--thin {
            }
            &--normal {
                border-top-width: 2px * @size-multiplier;
            }
            &--thick {
                border-top-width: 3px * @size-multiplier;
            }
            &--extra-thick {
                border-top-width: 5px * @size-multiplier;
            }
        }
    }

    // Reduce Separator margins when columns are collapsed, similar to the Spacer element
    .jw-layout-collapsed({
        --jw-element-separator__margin: 0.5em !important;

        // Set custom property so that the Editor can pick up on it
        // (we don t show the resizer on mobile layouts)
        --jw-separator-forced-margins: 1;
    });
}
