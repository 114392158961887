.jw-webshop-product-placeholder {
    display: block;
    padding: 20px;
    text-align: center;

    border: 3px dashed #5d5d5d;
    border-radius: 2px;
    background: none;

    .empty-text {
        max-width: 50%;
        margin-left: auto;
        margin-right: auto;

        line-height: 1.1;
        text-align: center;
        color: #5d5d5d;
        font-family: 'Segoe UI', 'Proxima Nova', Arial, sans-serif;
        font-weight: bold;

        user-select: none;
    }

    .no-top-margin {
        margin-top: 0px !important;
    }
}

.product {
    position: relative;

    display: flex;
    flex-direction: row;

    min-width: 100px;
    padding: 1.5em 0;

    border: 1px solid;
    border-left-width: 0;
    border-right-width: 0;
    box-shadow: none;

    &__image-container {
        margin-right: 1.5em;
    }
}

.jw-product + .jw-product .product {
    padding: 1.5em - @element-gap-block 0 1.5em;
    border-top-width: 0;
}

.jw-columns .product,
.jw-columns .jw-product + .jw-product .product {
    padding: 10px 15px 15px;

    border-width: 1px;
}

.jw-responsive.lt600 {
    .product {
        padding: @element-gap-block 0 @element-gap-block;
    }

    .jw-product + .jw-product .product {
        padding: 1em - @element-gap-block 0 @element-gap-block;
    }

    .jw-columns {
        .product {
            padding: 1em - @element-gap-block 0 @element-gap-block;
            border-radius: 0;
            border-top-width: 0;
            border-left-width: 0;
            border-right-width: 0;
        }
    }
}

.product__heading {
    display: inline-block;
    margin: 0;
}

.product__image-container .product-sticker {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 0;

    .lt400 & {
        display: none;
    }
}

.product__top .product-sticker {
    display: inline-block;
}

.product__responsive-top .product-sticker {
    width: -webkit-fit-content;
    width: fit-content;
}

.product__image-container {
    position: relative;
    flex: 0 0 auto;
    width: 180px;
    min-height: 100px;
}

.product__image {
    width: 100%;
    object-fit: contain;
}

.product__container {
    display: block;
    flex: 1 1 auto;
}

.product__top {
    margin-bottom: 10px;

    .lt400 & {
        max-width: 100%;

        .product__heading {
            overflow-wrap: break-word;
            max-width: 100%;
        }
    }
}

.product__variants {
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    box-sizing: border-box;
    overflow: hidden;

    &.jw-select {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.product__variants-select option:first-child {
    font-weight: bold;
}

.product__description,
.product__long-description {
    // prevent flexbox wrapping issues with very long words
    word-break: break-word;

    margin-bottom: 10px;
    clear: both;

    p {
        margin: 0;
    }
}

.product__long-description {
    &__content {
        display: none;
    }
}

.product__price {
    display: inline-block;
    text-decoration: none;
    font-size: 1.2em;
    line-height: 1.2em;
    font-variant-numeric: lining-nums;

    s {
        font-size: 0.8em;
        line-height: (1.2em / 0.8);
        vertical-align: top;

        opacity: 0.6;
        text-decoration: line-through;
    }
}

.product__price-disclaimer {
    display: inline-block;
    font-size: 0.7em;
    line-height: (1.2em / 0.7);
    vertical-align: top;

    .product-gallery--size-sm & {
        display: block;
        font-size: 0.6em;
    }
}

.product__free-shipping-motivator {
    display: inline-block;
    font-weight: bolder;
    font-size: 0.9em;

    // Color set in webshop-product.template.less
}

.product__responsive-top {
    display: none;
}

// Inspired by context.less (reference import of mixin wasn't sufficient).
.product__overlay {
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: 99;
    border-radius: 3px;
    border: 0 solid @brand-primary;

    &:hover {
        border-width: 1px;
    }
}

.product--row {
    padding: 0;
    border: 0;
    margin-bottom: 1em;

    .product__image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
    }

    .product__image {
        max-height: 100px;
    }

    .product__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 80%;
    }

    .product__top {
        margin-bottom: 0;
    }

    .product__heading,
    .product__price,
    .product__heading-addition {
        margin: 0.1em 0;
    }

    .product__heading {
        font-family: inherit;
        font-size: 1.2em;
    }

    .product__summary {
        display: flex;
        align-items: baseline;
    }

    .product__price {
        font-size: 1em;
    }

    .lt600 .product& {
        flex-direction: row;
        padding: 0;

        .product__image-container {
            flex: 0 1 100px;
            max-width: 25%;
            min-height: 0;
            margin: 0 10px 0 0;
        }
    }

    .lt400 .product& {
        .product__heading {
            font-size: 1em;
        }
    }

    .jw-popover-container--inline .product& {
        min-height: 4em;

        .product__image-container {
            flex: 0 1 80px;
            max-width: 20%;
            min-height: 0;
            margin: 0 10px 0 0;
        }

        .product__image {
            max-height: 80px;
        }

        .product__heading {
            font-size: 1em;
        }
    }
}

.lt600 {
    .product {
        flex-direction: column;
    }
    .product__image-container {
        width: auto;
        float: none;
        margin: 0 0 10px;
    }
    .product__top {
        margin-top: 0;
    }
    .product__description {
        margin-bottom: 10px;
    }
}

.lt400 {
    .product__top {
        display: none;
    }

    .product__responsive-top {
        display: block;
    }

    .product__responsive-top + .product__container {
        margin-top: 10px;
    }

    .product__image-container {
        margin: 10px 0;
    }
}

.lt400 {
    .product__button-container {
        display: flex;
        flex-wrap: wrap;

        .product__add-to-cart {
            flex-grow: 1;
        }

        button + button {
            margin-left: 0.3em;
        }
    }

    .product__variants {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }
}

.product__image {
    padding-top: 0;
}

.product-properties {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    margin-top: -1em;
    margin-left: -1em;
    & > * {
        margin-top: 1em;
        margin-left: 1em;
    }
}

.product__property {
    display: flex;
    flex-direction: column;
    min-width: 10em;
}

.product__property label {
    display: block;
}

.product__property,
.product-field-container {
    .jw-select,
    .jw-element-form-input-text {
        width: 100%;
    }
}

.product__image-container--placeholder {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 55px;
        height: 55px;
    }
}

.product-field-container {
    margin-bottom: 1rem;
}

.product__button-notice {
    color: @jw-element-form-error;
    margin: 1em 0 0.4em 0;
}

.product {
    .product-image {
        align-items: center; // fix Safari issue with `height: auto` elements in a flex container

        &__image--main {
            height: auto;
        }
    }
}

.jw-is-strips {
    // TODO#strips: temporary fix. Remove top/bottom padding on default of the first default
    // strip of the product detail page content, because otherwise the spacing looks weird.
    // We could restructure the breadcrumbs/heading/meta to render inside the first strip
    // (aka a lot of work).
    .product-page__content {
        .jw-strip--color-default.jw-node-is-first-child {
            padding-top: 0;
        }
    }
}
