.product-sticker {
    padding: 0 0.5em;
    margin-left: 1em;
    margin-bottom: 0.5em;

    /* this float helps on displaying the product sticker
     to the right on the product element */
    float: right;

    text-align: right;

    &--sold-out {
        // sold out sticker colors
        // These should not be overridden with template colors, hence the !important
        color: #fff !important;
        background-color: #424c51 !important;
    }
}

// Shared by product and product-gallery elements
.product-image {
    position: relative;
    display: flex;

    &--has-popup {
        cursor: zoom-in;
    }

    &__image {
        width: 100%;
        height: 100%;

        object-fit: contain;
    }

    &__image--variant {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
