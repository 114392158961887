@import './variables';

.jw-bottom-bar__container {
    display: flex;
    flex-direction: column;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: @zindex-bottom-bar;

    transition: transform 200ms ease;

    &--is-scrolling {
        transition: none;
    }
}

.jw-mobile-bar-container {
    order: 1;
}

.jw-website-button {
    order: 2;
}
