@jw-element-form-error: #d9534f;

//comment list css
//-----------------------
.jw-comments {
    padding: 0;
    margin: 0;
    list-style: none;
}

.jw-comments-pagination,
.jw-comments-loader {
    display: none;
}

//states
.jw-comments-container.is-loading-comments {
    .jw-comments-loader {
        display: block;
    }
}
.jw-comments-container.is-more-comments {
    .jw-comments-pagination {
        display: block;
    }
}

//specifice comment css
//----------------------
.jw-comment {
    padding: 1em;
    margin-top: 0.5em;
    position: relative;

    border-width: 1px;
    border-style: solid;
    border-radius: @border-radius-base;

    &:first-child {
        margin-top: 0;
    }
}

.jw-comment-name {
    font-weight: bold;
}

.jw-comment-head {
    .clearfix();
}

.jw-comment-text {
    margin-bottom: 0;
}

.jw-comment-actions {
    float: right;
    opacity: 0.75;
}

.jw-comment-error {
    display: none;
}

.jw-element-form-is-error.captcha-widget iframe {
    border: @jw-element-form-error 1px solid;
}

.jw-comment ~ .jw-comment-form {
    margin-top: 1em;
    margin-left: 3em;
}

.jw-subcomments {
    padding: 0.5em 0 1em 3em;
}

//states
.jw-comment.is-not-visible {
    display: none;
}

.lt400 {
    .jw-comment-name,
    .jw-comment-actions {
        float: none;
    }
}

.jw-is-editor {
    // replying doesn't work from the Editor (https://github.com/Webador/jouwweb/issues/6083)
    .jw-comment-react {
        display: none;
    }
}
