@import './variables';
@import '../../backend/base/variables';
@import '../../backend/bootstrap-variables';

@ui-resizable-size: 20px;
@ui-resizable-half: (@ui-resizable-size / 2);
@ui-resizable-border-width: 1px;
@ui-resizable-arrow-size: 8px;
@ui-resizable-arrow-gap: 2px;

.ui-resizable-handle {
    position: absolute;
    z-index: @zindex-jw-element-handles !important;
    box-sizing: border-box;
    width: @ui-resizable-size;
    height: @ui-resizable-size;

    color: @gray-lighter;
    background: currentcolor;
    border-radius: 100%;
    border: @ui-resizable-border-width solid white;

    transition:
        color 150ms ease,
        opacity 150ms ease,
        visibility 150ms ease;

    &:hover,
    &:active {
        color: @brand-primary;
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        display: block;
    }

    &::before {
        top: ((@ui-resizable-size - @ui-resizable-arrow-size) / 2) -
            @ui-resizable-border-width;
        left: ((@ui-resizable-size - @ui-resizable-arrow-size) / 2) -
            @ui-resizable-border-width;
        width: @ui-resizable-arrow-size;
        height: @ui-resizable-arrow-size;

        background: @white;
        transform: rotate(-45deg);
    }

    &::after {
        top: 2px;
        bottom: 2px;
        left: ((@ui-resizable-size - @ui-resizable-arrow-gap) / 2) -
            @ui-resizable-border-width;
        width: @ui-resizable-arrow-gap;

        background: currentcolor;
    }
}

.ui-resizable-e {
    cursor: ew-resize;
    right: -@ui-resizable-half;
    top: 50%;
    margin-top: -@ui-resizable-half;
}

.ui-resizable-s {
    cursor: ns-resize;
    left: 50%;
    margin-left: -@ui-resizable-half;
    bottom: -@ui-resizable-half;
    transform: rotate(90deg);
}

.ui-resizable-n {
    cursor: n-resize;
    left: 50%;
    top: -@ui-resizable-half;
    margin-left: -@ui-resizable-half;
    transform: rotate(90deg);
}

.ui-resizable-w {
    cursor: ew-resize;
    top: 50%;
    left: -@ui-resizable-half;
    margin-top: -@ui-resizable-half;
}

.ui-resizable-sw {
    cursor: sw-resize;
    left: -@ui-resizable-half;
    bottom: -@ui-resizable-half;
    transform: rotate(-45deg);
}

.ui-resizable-nw {
    cursor: nw-resize;
    left: -@ui-resizable-half;
    top: -@ui-resizable-half;
    transform: rotate(45deg);
}

.ui-resizable-ne {
    cursor: ne-resize;
    right: -@ui-resizable-half;
    top: -@ui-resizable-half;
    transform: rotate(-45deg);
}

.ui-resizable-se {
    cursor: se-resize;
    right: -@ui-resizable-half;
    bottom: -@ui-resizable-half;
    transform: rotate(45deg);
}
