.jw-poll-bar {
    position: relative;
    max-width: 400px;
}

.jw-poll-width {
    box-sizing: border-box;
    padding: 0.2em 0.5em;
    font-size: 0.9em;
    min-width: 3em;
    border-radius: @border-radius-base;
    border: 1px solid transparent;
}

.jw-poll-results {
    .jw-element-form-label {
        font-weight: normal;
    }
    .jw-poll-vote-count {
        font-weight: bold;
    }
}
