.cart__items {
    margin-bottom: @element-gap-block;
}

.cart__overview {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1em;

    .lt600 & {
        flex-direction: column;
    }
}

.cart__overview-block {
    flex: 0 0 60%;
    margin: 0 0 1em;
    padding: 0 1em;
    box-sizing: border-box;

    .lt600 & {
        order: 1;
        // Override inline style
        flex-basis: auto !important;
    }

    & > .cart__overview-block {
        margin-left: -1em;
        margin-right: -1em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.is-auto-width {
        flex: 1 0 auto;
    }
}

.cart__overview-totals {
    flex-basis: 40%;
    min-width: 240px;
    margin-left: auto !important;

    .lt600 & {
        order: 0;
        min-width: 0;
        margin-left: 0 !important;
        flex-basis: auto;
    }
}

.cart__coupon-input,
.cart__shipping-estimation {
    margin-bottom: 1em;
}
.cart__coupon-input-error:not(:empty) {
    &:extend(.jw-element-form-error);
    background-color: @jw-element-form-error;
    border-color: @jw-element-form-error;
    color: white;
    margin-top: 1em;
}
.cart__coupon-input-warning:not(:empty) {
    &:extend(.jw-element-form-error);
    background-color: @jw-element-form-warning;
    border-color: @jw-element-form-warning;
    color: black;
    margin-top: 1em;
}

.cart-totals {
    margin-bottom: 1em;

    .lt600 & {
        margin-bottom: 0;
    }
}

.cart-totals__row {
    display: flex;
    align-items: center;
    min-height: 2.3em;

    &--free_shipping_motivator {
        .cart-totals__row-description {
            margin: 0;
            font-weight: bolder;
        }
    }

    &--free_shipping {
        .cart-totals__row-price {
            text-transform: uppercase;
            font-weight: bolder;
        }
    }

    &--total,
    &--total_tax_hidden {
        align-items: flex-start;

        margin-top: 0.5em;
        padding-top: 0.5em;

        border-top: 1px solid;
        font-weight: bold;
        font-size: 1.2em;

        &:first-child {
            margin-top: 0;
            padding-top: 0;

            border-top: 0;
        }
    }

    &--small {
        align-items: center;

        border-top: 0;
        margin-top: 0;
        padding-top: 0;

        font-size: 1em;
        min-height: 0;

        .cart-totals__row-description {
            margin-left: auto;
            text-align: right;
        }

        .cart-totals__row-price {
            min-width: 6em;
            margin-left: 0;
        }

        &:first-child {
            border-top-width: 1px;
            border-top-style: solid;
            padding-top: 0.5em;
        }

        &:last-child {
            padding-bottom: 1em;
        }
    }
}

.cart-totals__row-description {
    flex: 0 1 auto;
    min-width: 150px;
    margin-right: 1em;

    .jw-element-form-input-text {
        max-width: 100%;
    }
}

.cart-totals__row-price {
    margin-left: auto;
    text-align: right;

    small {
        font-weight: normal;
    }
}

#paypalButtons,
.accepted-payment-method-container {
    width: 200px;

    @media (min-width: 620px) {
        width: 285px;
    }
}

.accepted-payment-method-container {
    text-align: right;
    margin-top: 2rem;
}

.accepted-payment-method {
    height: 3rem;
    margin: 0 0.15rem;
}

.inline-cart-no-content-message {
    text-align: center;
}

.cart-product-suggestions {
    margin-top: 5rem;
}

// Override styles defined in elements/form.less
.jw-webshop-form .jw-element-form-input-text {
    &.cart__shipping-estimation-country {
        margin: 0 1em 1em 0;

        // Fixed widths to not have the selects jump around when their content changes
        width: 200px;

        @media (max-width: 620px) {
            width: 100%;
        }
    }

    &.cart__shipping-estimation-method {
        // Overwrite margin because this element wraps
        margin-left: 0 !important;

        width: 200px;

        @media (max-width: 620px) {
            width: 100%;
        }
    }
}

// Show a Paypal button at the last step of the checkout.
// Based on the design at https://developer.paypal.com/docs/checkout/integration-features/customize-button/.
.paypal-button {
    background: #ffc439;
    border-radius: 1.2em;
    color: #111;
    border: 0;
    padding: 0.6em 3em;
    line-height: 0;

    &:focus,
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);
    }

    img {
        margin: 0;
    }
}
