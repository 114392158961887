.jw-pagination {
    display: flex;
    justify-content: center;

    // TODO: This should maybe be moved to the element above
    margin-top: 1em;

    ul {
        display: flex;

        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        height: 2.4em;

        // Dots
        &:before {
            display: inline-flex;
            align-items: center;
            height: 100%;
            padding: 0 0.5em;
        }

        &.hidden + .jw-pagination__item:not(.hidden),
        .lt400 &.hidden-lt400 + .jw-pagination__item:not(.hidden-lt400) {
            &:before {
                content: '…';
            }
        }

        // No dots in narrowest view
        .lt300 & {
            &:before {
                content: none !important;
            }
        }
    }

    // Prev/next controls
    &__control {
        &--hidden {
            opacity: 0;
            visibility: hidden;
        }

        &:first-child {
            margin-right: 1em;
        }
        &:last-child {
            margin-left: 1em;
        }
    }

    .jw-btn {
        height: 100%;
        padding: 0 0.5em;
        box-sizing: border-box;
    }

    &__page,
    &__small {
        display: inline-flex;
        height: 100%;
        padding: 0 0.7em;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }

    // Page numbers
    &__page {
        cursor: pointer;

        &--active {
            font-weight: bold;
        }
    }
}
