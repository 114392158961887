// Default toolbar height, equals the toolbar height from TinyMCE. Will be
// updated via JavaScript if the toolbar height changes (e.g. due to wrapping).
@default-sticky-toolbar-height: 36px;

// Spacing between toolbar and top of the reference element. Should leave space
// for the context outline buttons.
@sticky-toolbar-spacing-top: 18px;

// When less than this height of the reference element is visible, the toolbar
// will stop being sticky and scroll with the reference element. This is to
// avoid the toolbar from losing context.
@sticky-toolbar-offset-bottom: 100px;

// Padding between the toolbar and the viewport.
@sticky-toolbar-viewport-padding: 10px;

// Markup:
// ```
// <div class="jw-sticky-toolbar">
//     <div class="jw-sticky-toolbar__toolbar">
//         ...
//     </div>
// </div>
// ```
//
// - .jw-sticky-toolbar: The wrapper for the toolbar. This covers a larger area
//   than the toolbar itself to allow for the toolbar to be sticky within bounds.
// - .jw-sticky-toolbar__toolbar: The toolbar itself.

.jw-sticky-toolbar {
    --jw-sticky-toolbar-height: @default-sticky-toolbar-height;

    position: absolute;
    z-index: @zindex-jw-floater;
    // Leave space above the reference element for the toolbar.
    top: calc(
        -1 * (var(--jw-sticky-toolbar-height) + @sticky-toolbar-spacing-top)
    );
    left: 0;
    right: 0;
    bottom: @sticky-toolbar-offset-bottom;

    // Since the wrapper covers a larger area than the toolbar itself, we need
    // to disable pointer events on the wrapper, so the underlying elements can
    // still be interacted with.
    pointer-events: none;
}

.jw-sticky-toolbar__toolbar {
    position: sticky;
    top: @sticky-toolbar-viewport-padding;
    // margin-left: <set by Floating UI>;

    // Don't wrap if there's enough space for the toolbar.
    width: max-content;
    // Wrap toolbar when it's too wide for the viewport.
    max-width: calc(100vw - 2 * @sticky-toolbar-viewport-padding);

    // Re-enable pointer events on the toolbar itself.
    pointer-events: initial;

    .mce-floatpanel {
        position: static !important;
        margin: 0 !important;
    }
}

// On mobile, the sticky behavior is disabled and instead the toolbar is always
// fixed to the top of the viewport.
.jw-sticky-toolbar--mobile {
    position: fixed;
    top: 0;
    bottom: auto;

    .jw-sticky-toolbar__toolbar {
        position: static;
        width: 100%;
        max-width: none;
    }
}

.jw-sticky-toolbar--appear-animation {
    --jw-sticky-toolbar--appear-initial-offset: 10px;

    &.jw-sticky-toolbar--mobile {
        --jw-sticky-toolbar--appear-initial-offset: -20px;
    }

    .jw-sticky-toolbar__toolbar {
        @keyframes jw-sticky-toolbar--appear {
            0% {
                transform: translateY(
                    var(--jw-sticky-toolbar--appear-initial-offset)
                );
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        animation: jw-sticky-toolbar--appear 150ms ease-out;
    }
}
