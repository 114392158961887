// This file is compiled into `/assets/css/frontend.css`.
//
// It contains CSS and components that are shared between all templates and are either used when editing or viewing the
// website.
//
// Note: everything that is included here is thus *also* visible when users visit the website. Be sure to hide editor
// only components!

// Include Bootstrap variables
@import '~bootstrap/less/variables.less';
@import '../backend/bootstrap-variables.less';
@import '../backend/base/variables.less';

// Import the `hidden-..` and `visible-..` helper classes.
@import '~bootstrap/less/mixins/responsive-visibility.less';
@import '~bootstrap/less/responsive-utilities.less';

@navbar-height: 55px;

@import 'module/normalize.less';

// Copy of Bootstrap
.clearfix() {
    &:before,
    &:after {
        content: ' '; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

// Copy of Bootstrap
.button-variant(@color; @background; @border) {
    color: @color;
    background-color: @background;
    border-color: @border;

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        color: @color;
        background-color: darken(@background, 10%);
        border-color: darken(@border, 12%);
    }
    &:active,
    &.active,
    .open > .dropdown-toggle& {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: @background;
            border-color: @border;
        }
    }

    .badge {
        color: @background;
        background-color: @color;
    }
}

// Copy of Bootstrap
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
    padding: @padding-vertical @padding-horizontal;
    font-size: @font-size;
    line-height: @line-height;
    border-radius: @border-radius;
}

// Copy of Bootstrap
// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content/
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@import '../shared/flags';
@import '../shared/mixins';

.default-focus-outline() {
    .focus-outline();
}

* {
    // This makes sure that if there is a fixed header and there is scrolled
    // towards an element, it will appear below the header instead of behind.
    // The css variable is set through JS.
    scroll-margin-top: var(--fixed-header-height);
}
@import 'module/credits.less';
@import 'module/element-image-text.less';
@import 'module/element-text.less';
@import 'module/sticky-toolbar';
@import 'module/website-spanner';
@import 'module/header';
@import 'module/resizer';
@import 'module/legacy.less';
@import 'module/states';
@import 'module/spinner';
@import 'module/context';
@import 'module/menu';
@import 'module/mobile-bar';
@import '../shared/message-bar';
@import 'module/bottom-bar';
@import 'module/bar-variations';
@import 'module/search';
@import 'module/loading';
@import 'module/image-gallery';
@import 'module/layout';
@import 'module/element-spacing.less';

@import 'module/elements/contact-form';
@import 'module/elements/flag';
@import 'module/elements/element';
@import 'module/elements/section-menu';
@import 'module/elements/tree';
@import 'module/elements/markings';
@import 'module/elements/overlay';
@import 'module/elements/image-text';
@import 'module/elements/image';
@import 'module/elements/rating';
@import 'module/elements/audio-video';
@import 'module/elements/document';
@import 'module/elements/html';
@import 'module/elements/spacer';
@import 'module/elements/sitemap';
@import 'module/elements/form';
@import 'module/elements/slideshow';
@import 'module/elements/download';
@import 'module/elements/map';
@import 'module/elements/heading';
@import 'module/elements/simple-root';
@import 'module/elements/columns';
@import 'module/elements/poll';
@import 'module/elements/table';
@import 'module/elements/comment';
@import 'module/elements/social-share';
@import 'module/elements/social-follow';
@import 'module/elements/separator';
@import 'module/elements/album';
@import 'module/elements/news';
@import 'module/elements/text';
@import 'module/elements/ads';
@import 'module/elements/button';
@import 'module/elements/website-button';
@import 'module/elements/website-logout';
@import 'module/elements/webshop-common';
@import 'module/elements/webshop-product';
@import 'module/elements/webshop-product-gallery';
@import 'module/elements/webshop-product-page';
@import 'module/elements/pagination';
@import 'module/elements/accordion';
@import 'module/elements/strip';

// Webshop
@import 'module/webshop/cart';
@import 'module/webshop/cart-item';
@import 'module/webshop/checkout';
@import 'module/webshop/steps';

@import 'module/account/page';
