.jw-section-menu-list-item.jw-section-menu-is-active {
    font-weight: bold;
}

.jw-section-menu-list {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        margin-bottom: 0.1em;
        &:before {
            content: '\00bb';
            margin-right: 0.3em;
        }
    }
}

.jw-section-menu-title {
    word-wrap: break-word;
}

// Make sure heading of section menu behaves the same as first heading in
//element container.
// TODO can this be improved?
.jw-section-menu {
    margin-bottom: 1em !important;
}
.jw-section-menu-title {
    margin-top: 0 !important;
}
