.message-bar--dark,
.mobile-bar--dark {
    background: @mobile-bar-background-dark;

    .mobile-bar__item,
    .message-bar-usps__item,
    .message-bar-text {
        color: @mobile-bar-text-dark;
    }

    .mobile-bar__item:hover {
        background: lighten(@mobile-bar-background-dark, 5%);
    }
}

.message-bar--light,
.mobile-bar--light {
    background: @mobile-bar-background-light;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1), 0 -2px 2px rgba(0, 0, 0, 0.1);

    .mobile-bar__item,
    .message-bar-usps__item,
    .message-bar-text {
        color: @mobile-bar-text-light;
    }

    .mobile-bar__item:hover {
        background: darken(@mobile-bar-background-light, 5%);
    }
}
