.jw-heading {
    margin-bottom: 0;
}

.jw-heading.jw-node-is-first-child h1 {
    margin-top: 0;
}

.jw-heading.jw-node-is-last-child h1 {
    margin-bottom: 0;
}

.heading__no-margin {
    margin-top: 0 !important;
}
