.jw-document-wrapper {
    width: 100%;
    background-color: white;
    position: relative;
    min-height: 178px;

    &:after {
        display: block;
        content: '';
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// Use this CSS to grant wrapper a responsive height based on its width. Ie to make the
// wrapper 150% percentage of the width use:
//
// .jw-document-wrapper:after { padding-top: 150%; }

.jw-document-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
