.jw-element,
.jw-webshop-form {
    &.jw-element-is-loading {
        opacity: 0.5;
        transition: opacity 200ms ease 300ms;
    }
}

// Instantly show loader for elements that scroll while loading (see #10053 and #10073)
.jw-product-gallery,
.jw-news {
    &.jw-element-is-loading {
        transition-delay: 0ms;

        .jw-element-loader {
            transition-delay: 0ms;
        }
    }
}

.jw-element-loader {
    // Loader is hidden by default
    visibility: hidden;
    pointer-events: none;
    opacity: 0;

    // Overlay the entire element
    position: absolute;
    z-index: @zindex-jw-element-overlay;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // Offset sticky spinner from top/bottom
    padding: 50px 0;

    .jw-spinner {
        display: block;

        // Center in container
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        // Center in visible area of container
        @supports (position: sticky) {
            position: sticky;
            top: 50%;
            top: calc(50% - 0.5em);
            bottom: 50%;
            bottom: calc(50% - 0.5em);
        }

        // Spinner size
        font-size: 50px;
    }

    .jw-element-is-loading & {
        visibility: visible;
        pointer-events: all;
        opacity: 1;

        // Show after a short delay, to prevent showing for quick loads
        transition: opacity 200ms ease 300ms;
    }
}
