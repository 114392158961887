.jw-slideshow {
    &:hover {
        .jw-slideshow-button {
            display: block;
        }
        .jw-element-overlay {
            cursor: auto;
            background: fade(black, 20%);
        }
        .image-list-delete {
            display: block;
        }
    }

    .jw-slideshow-slide {
        .jw-slideshow-slide-container,
        .jw-slideshow-slide-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .jw-slideshow-slide-video {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
        }

        .jw-slideshow-slide-video-frame {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.jw-slideshow-slide-backdrop {
    position: absolute;
    background: black;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.4;
}

.jw-slideshow-button {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -16px;
    margin-left: -50px;
}

//increase specificity over bootstrap's
.jw .jw-slideshow-button {
    display: none;
}

//state when resizing the slideshow
.ui-resizable-resizing {
    .bx-wrapper,
    .bx-viewport {
        overflow: hidden;
        max-height: 100%;
    }
}

.jw-slideshow--parallax {
    .jw-slideshow-slide-content {
        will-change: background-position;
    }
}

.jw-slideshow .jw-slideshow-slide-align-left {
    .bx-caption {
        text-align: left;
    }
}

.jw-slideshow .jw-slideshow-slide-align-right {
    .bx-caption {
        text-align: right;
    }
}

.jw-slideshow .jw-slideshow-slide-align-left,
.jw-slideshow .jw-slideshow-slide-align-right {
    padding: 0 15px;
}

#jw {
    .jw-slideshow-loader {
        display: none;

        .jw-element-is-loading & {
            display: flex;
        }
    }
}
