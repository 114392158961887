.focus-outline(@outline: 1px dotted currentColor, @offset: 0) {
    &:focus {
        outline: @outline;
        outline-offset: @offset;

        // Undo :focus styles if not :focus-visible
        &:not(:focus-visible) {
            outline: none;
        }
    }
}
