@mobile-bar-height: 75px;
@mobile-bar-item-width: 90px;

@mobile-bar-background-dark: #333;
@mobile-bar-text-dark: #fff;

@mobile-bar-background-light: #eee;
@mobile-bar-text-light: #333;

.mobile-bar {
    display: none;
    flex-direction: row;
    justify-content: center;

    height: @mobile-bar-height;
    padding: 0 10px;

    list-style: none;

    font: 13px @font-family-base;

    // Show mobile-bar while its settings are opened
    .jw-is-mobile-bar-editing & {
        display: flex;
    }
}

@media (max-width: 450px) {
    .mobile-bar {
        display: flex;
    }

    .jw-website-spacer--mobile-bar {
        height: @mobile-bar-height;
    }
}

// Hide mobile bar when viewport height becomes too small
@media (max-width: 450px) and (max-height: 440px) {
    .mobile-bar,
    .jw-website-spacer--mobile-bar {
        display: none;
    }
}

.mobile-bar__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    min-width: 50px;

    text-decoration: none;

    transition: background 200ms ease;

    &:hover {
        text-decoration: none;
    }
}

.mobile-bar__item-icon {
    font-size: 1.4em;
    line-height: 1;
}

.mobile-bar__item-label {
    padding-top: 0.4em;

    font-size: 0.9em;
    line-height: 1;
}
