@import '~flag-icons/less/flag-icons-base';
@import '~flag-icons/less/variables';

@flag-icon-css-path: '~flag-icons/flags';

.flag-icon {
    .fi;
    font-size: 14px;
}

.jw-flag-icon(@country) {
    .flag-icon-@{country} {
        background-image: ~'url(@{flag-icon-css-path}@{flag-icons-rect-path}/@{country}.svg)';
    }
}

// Only include flags that are actually supported by the application. See LocaleUtils::FLAG_MAP.

.jw-flag-icon(at);
.jw-flag-icon(be);
.jw-flag-icon(ca);
.jw-flag-icon(ch);
.jw-flag-icon(de);
.jw-flag-icon(dk);
.jw-flag-icon(ee);
.jw-flag-icon(es);
.jw-flag-icon(fi);
.jw-flag-icon(fr);
.jw-flag-icon(gb);
.jw-flag-icon(hu);
.jw-flag-icon(ie);
.jw-flag-icon(it);
.jw-flag-icon(mx);
.jw-flag-icon(nl);
.jw-flag-icon(no);
.jw-flag-icon(pl);
.jw-flag-icon(pt);
.jw-flag-icon(ru);
.jw-flag-icon(se);
.jw-flag-icon(tr);
.jw-flag-icon(us);
.jw-flag-icon(xx);
