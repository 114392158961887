.jw-element {
    /* contenteditable divs have this property automatically,
    make a consistent look in the templates by adding this property */
    word-wrap: break-word;
    width: 100%;

    // Disable grey hightlight on draggable elements
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    // Resolve your own cursor
    cursor: auto;

    // When auto-scrolling to an element, try to leave some margin between it and the viewport
    scroll-margin: calc(var(--fixed-header-height, 0px) + 30px) 0;

    .jw-is-backend &.jw-element {
        &--fade-exit {
            opacity: 1;
            transform: scale(1);

            &-active,
            &-done {
                opacity: 0;
                transform: scale(0.9);
            }

            &-active {
                transition:
                    opacity 200ms ease,
                    transform 200ms ease;
            }
        }

        &--height-exit {
            overflow: hidden;

            &-active,
            &-done {
                height: 0 !important; // override inline style
                margin-bottom: 0 !important; // override !important style
            }
            &-active {
                transition:
                    height 200ms ease,
                    margin-bottom 200ms ease;
            }
        }

        &--width-exit {
            overflow: hidden;

            &-active,
            &-done {
                width: 0 !important;
                padding-left: 0;
                padding-right: 0;
            }
            &-active {
                transition:
                    width 200ms ease,
                    padding 200ms ease;
            }
        }
    }

    &--is-moving-placeholder {
        // When moving a brick, we reduce the opacity of its content to leave
        // a visible gap as an indication of what is being moved. For most
        // bricks it's sufficient to reduce the opacity of the element itself.
        // But the columns brick renders some additional UI that we do not want
        // to fade out, so we instead reduce the opacity of its direct children.

        &:not(.jw-columns),
        &.jw-columns > .jw-tree-node {
            opacity: 0.2;
        }

        &.jw-element--highlight-self {
            &:not(.jw-columns),
            &.jw-columns > .jw-tree-node {
                opacity: 0.3;
            }
        }
    }
}

//only apply img max-width to elements which can have a image inserted by a user
.jw-html,
.jw-image-text,
.jw-image,
.jw-news {
    img {
        max-width: 100%;
        height: auto;
    }
}
