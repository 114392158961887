@import (reference) './layout.less';

// This file contains the spacing between all elements.
// This should make it clear what the spacing is and what exceptions there are.
@element-gap-block: 1em;

// Give every element a margin around it by default, except for the first and last
.jw-element {
    margin-top: @element-gap-block;
    margin-bottom: @element-gap-block;
    &.jw-node-is-first-child {
        margin-top: 0;
    }
    &.jw-node-is-last-child {
        margin-bottom: 0;
    }
}

// We remove the margin here, so if two of these elements are placed next together it uses the default margin
// on a paragraph. Other elements will have the default margin.
.jw-image-text {
    margin-top: 0;
    margin-bottom: 0;
}

.jw-column {
    + .jw-column {
        margin-top: @element-gap-block * 3;
    }
    // Only apply margin on mobile; on desktop the margin is applied to the container
    .jw-layout-full({
        margin-top: 0;
        margin-bottom: 0;
    });
}

// todo#strips: remove .jw-is-strips once all templates are strip compatible
.jw-is-strips {
    .jw-strip {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.jw-simple-root {
    margin-top: 0;
    margin-bottom: 0;

    // TODO#strips: Make this work with strips
    // Add margin between repeated root containers with children. Don't add when
    // empty.
    &:not(.jw-node-is-last-child):not(.jw-tree-container--empty) {
        margin-bottom: @element-gap-block;
    }
    &:not(.jw-node-is-first-child):not(.jw-tree-container--empty) {
        margin-top: @element-gap-block;
    }
}
