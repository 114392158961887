@jw-element-form-radius: 2px;
@jw-element-form-success: #5cb85c;
@jw-element-form-error: #d9534f;
@jw-element-form-warning: #fff1c5;

//Alert boxes
.jw-element-form-success,
.jw-element-form-error,
.jw-element-form-info {
    padding: 0.4em 0.8em;
    margin: 0 0 1em;
    border-radius: @jw-element-form-radius;
    border: 1px solid;
}

//Basic form styling
.jw-element-form-group {
    margin: 0 0 0.8em 0;

    &-small {
        margin-bottom: 0.5em;
    }

    .clearfix();
}

.jw-element-form-label {
    float: left;
    width: 25%;
    padding-top: 0.5em;
    padding-right: 2em;
    box-sizing: border-box;

    font-weight: bold;

    &.jw-element-form-is-error {
        color: @jw-element-form-error;
    }
}

.jw-element-form-content {
    float: left;
    width: 75%;
    box-sizing: border-box;

    .jw-element-form-input-text {
        width: 100%;
    }

    // Flexy columns in form content.
    //
    // Example structure:
    //  <div class="jw-element-form-content jw-element-form-content--row">
    //      <div class="jw-element-form-content__column">
    //          <div class="jw-element-form-content__input">
    //              <input type="text" />
    //          </div>
    //      </div>
    //      <div class="jw-element-form-content__column jw-element-form-is-error">
    //          <div class="jw-element-form-content__input">
    //              <input type="text" />
    //          </div>
    //          <div class="jw-element-form-help">
    //              Error message
    //          </div>
    //      </div>
    //  </div>
    &--row {
        display: flex;
        align-self: baseline;
        max-width: 100%;
    }

    &__column {
        display: flex;
        flex-direction: column;
        margin-left: 1em;

        // Shrink columns on smaller devices
        flex: 0 1 auto;
        min-width: 0;

        &:first-child {
            margin-left: 0;
        }

        .jw-element-form-help {
            width: 100%;
            // Shrink help text to the size of the input in the same column
            max-width: fit-content;

            // No need to "cancel out margin of previous form group", since this
            // element is placed in the same container as the input.
            margin-top: 0;
        }
    }

    &__input {
        display: flex;
    }
}
.jw-element-form-offset {
    margin-left: 25%;
}

//to stack label and content
.jw-element-form-stack {
    .jw-element-form-label,
    .jw-element-form-content {
        width: auto;
        float: none;
    }
}

//Form elements
.jw-element-form-input-text {
    max-width: 400px;
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 2px;
    transition:
        background-color ease 100ms,
        border-color ease 100ms;
    padding: 0.5rem 1rem;
    -webkit-appearance: none;

    &:focus {
        outline: 0;
    }

    &::placeholder {
        opacity: 0.7;
        color: currentColor;
        font-style: italic;
    }
}

// Custom select design, based on https://www.filamentgroup.com/lab/select-css.html
select.jw-element-form-input-text {
    background-repeat: no-repeat, repeat;
    background-position:
        right 0.7em top 50%,
        0 0;
    background-size:
        0.65em auto,
        100%;
    padding-right: 1.5em;

    &::-ms-expand {
        display: none;
    }
}

.jw-element-form-input-checkbox,
.jw-element-form-input-radio {
    vertical-align: middle;
    margin: 0.3em;
}

textarea {
    min-height: 6em;
}

select.jw-element-form-input-text > option {
    color: #222;
    background: #fff;
}

.jw-toggle-block {
    position: relative;
    margin-bottom: 0.8em;

    input&__control {
        position: absolute;
        top: 50%;
        left: 1.3em;
        width: 1em;
        height: 1em;

        transform: translate(0, -50%);

        .jw-element-form-is-error & {
            // Inline error increases height of containing element so we need to move the control up. Works best for
            // single line inputs (checkboxes in webshop checkout confirmation).
            top: 40%;
        }

        &:checked ~ .jw-toggle-block__label {
            font-weight: bold;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        min-height: 4em;
        padding: 0 1em 0 3.1em;
        box-sizing: border-box;

        border: 1px solid;
        border-radius: 0.2em;

        transition:
            border-color 150ms ease,
            box-shadow 150ms ease;

        // Remove final margin child paragraphs to keep them centered.
        p:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        flex: 0 1 auto;
        line-height: 1em;
    }

    .jw-issuer,
    &__title {
        margin-right: 0.8em;
    }

    .jw-toggle-block__button {
        margin-left: auto;
    }

    &__images {
        display: flex;
        flex: 0 0 40px;
        padding: 0.5em 0.8em 0.5em 0;

        & > img {
            flex: 0 1 auto;
            min-width: 0;
            height: 2.5em;

            & + img {
                margin-left: 0.8em;
            }
        }
    }
}

.jw-element-form-well {
    padding: 1em;

    border-radius: 0.2em;

    .jw-element-form-group:last-child {
        margin-bottom: 0;
    }
}

.jw-form-footer {
    display: flex;
    align-items: baseline;
    margin-top: 2em;

    &__next {
        margin-left: auto;
        text-align: right;
    }
}

.jw-webshop-form {
    @row-height: 2.4em;

    &[tabindex='-1']:focus {
        outline: 0;
    }

    .jw-element-form-group {
        display: flex;
        align-items: baseline;

        .lt400 & {
            flex-direction: column;
            align-items: stretch;
        }

        &::before,
        &::after {
            display: none;
        }
    }

    .jw-element-form-label {
        display: flex;
        flex-direction: column;
        justify-content: center;

        max-height: @row-height;
        padding: 0 1em 0 0;

        line-height: 1;
        text-align: right;

        &.has-sub-label {
            align-self: stretch;
        }

        small {
            display: block;
            opacity: 0.7;
        }

        .lt400 & {
            margin-bottom: 0.3em;
        }
    }

    .jw-element-form-content {
        .lt400 & {
            margin-bottom: 0.3em;
        }
    }

    .jw-element-form-input-text {
        width: auto;
        max-width: 100%;
        height: @row-height;
        flex: 0 1 auto;
        min-width: 0;

        &:not(.hidden) + .jw-element-form-input-text {
            margin-left: 1em;
        }
    }

    // Both label and input are rendered with the same class so the label is necessary
    .jw-element-form-radio + label {
        display: inline-block;
        margin: 0 1em 0 0;
    }
}

//States

.lt600 {
    .jw-element-form-offset {
        margin-left: 35%;
    }
    .jw-element-form-label {
        width: 35%;
    }
    .jw-element-form-content {
        width: 65%;
    }
}

.lt400 {
    .jw-element-form-content,
    .jw-element-form-label,
    .jw-element-form-submit {
        float: none;
        width: auto;
        text-align: left;
        margin-left: 0;
    }
    .jw-element-form-offset {
        margin-left: 0px;
    }
}

.jw-element-form-help {
    font-size: @font-size-small;

    .jw-element-form-is-error & {
        color: @jw-element-form-error;
    }
}

.jw-element-form-is-error .captcha-widget iframe {
    border: @jw-element-form-error 1px solid;
    border-radius: @jw-element-form-radius;
}
