@jw-download-big-size: 3.5em;
@jw-download-small-size: 2.5em;

.jw-download {
    &-content {
        display: flex;
        align-items: center;
    }

    &-image {
        margin-top: 0.4em;
        width: @jw-download-small-size;
        line-height: 0;

        svg {
            width: 100%;
        }

        .jw-download-is-filetype &,
        .jw-download-is-downloads & {
            width: @jw-download-big-size;
        }
    }

    &-data {
        margin-left: 1em;
    }

    &-title {
        font-weight: bold;
    }

    &-mime {
        display: none;

        .jw-download-is-filetype & {
            display: inline-block;
        }
    }

    &-downloads {
        display: none;

        .jw-download-is-downloads & {
            display: inline-block;
        }

        // Add separator between file type and download counter
        .jw-download-is-filetype &::before {
            content: ' – ';
        }
    }

    &-button {
        margin-top: 0.2em;
    }
}
