@import '../../shared/mixins';

//Setting inset box-shadow
.inset-shadow(@colour, @percentage:@inset-shadow-darken, @distance: @inset-shadow-distance, @size: 0) {
    @shadow: inset 0 @distance @size darken(@colour, @percentage);
    .box-shadow(@shadow);
}

.input-border() {
    border: 1px solid @input-border;
    background-clip: padding-box;
    border-radius: (
        @border-radius-base + 1
    ); //+1 because of background-clipping with border of 1px
}

/* Give element a background color and an inset-shadow which is a little darker */
.color-variant(@color) {
    background: @color;
    .inset-shadow(@color);
}
.color-variant-percentage(@color, @percentage) {
    background: @color;
    .inset-shadow(@color, @percentage);
}

//alert variant bootstrap variant
.alert-variant(@background; @border; @text-color) {
    background-color: @background;
    border-color: @border;
    color: @text-color;

    hr {
        border-top-color: darken(@border, 5%);
    }

    .alert-link {
        color: @text-color;
        text-decoration: underline;
        &:hover {
            color: mix(@text-color, @background, 75%);
        }
    }
}

.jw-button-variant(
    @selector;
    @color;
    @bg;
    @bg-hover: shade(@bg, 10%);
    @bg-active: shade(@bg, 20%);
    @border: fade(black, 17%);
    @border-hover: @border;
    @border-active: @border;
    @shadow-color: fade(black, 15%);
    @bold: false;

    @has-inverse-variant: true;
    @border-inverse: fade(white, 10%);
    @border-inverse-hover: @border-inverse;
    @border-inverse-active: fade(black, 5%);
    @shadow-inverse: 0 1px 2px fade(black, 25%);
) {
    @{selector} {
        @default-styles: {
            color: @color;
            background-color: @bg;
            border-color: @border;
            box-shadow: 0 1px 2px @shadow-color;
        };

        &:hover,
        &:focus {
            color: @color;
        }

        &:hover {
            background-color: @bg-hover;
            border-color: @border-hover;
        }

        &:active {
            background-color: @bg-active;
            border-color: @border-active;
        }

        &,
        &[disabled],
        &.disabled {
            @default-styles();
        }

        & when (@bold = true) {
            font-weight: 600;

            & when (luma(@color) > luma(@bg)) {
                text-shadow:
                    0 1px 1px fade(black, 10%),
                    0 0 2px fade(black, 30%);
            }
        }
    }

    .open > @{selector}.dropdown-toggle {
        background-color: @bg-active;
        border-color: @border-active;
    }

    .btn--border@{selector} {
        &:not(:hover):not(:active) {
            background: none;
            border-color: @bg;
            box-shadow: none;

            color: @bg;
            text-shadow: none;
        }
    }

    & when (@has-inverse-variant = true) {
        // Use inverse variant of the button in dark contexts
        .btn-context--inverse & @{selector}, // Support cases where the context class is a parent of the #jw container
        .btn-context--inverse @{selector},
        .btn--inverse@{selector} {
            @default-styles: {
                border-color: @border-inverse;
                box-shadow: @shadow-inverse;
            };

            &:hover {
                border-color: @border-inverse-hover;
            }

            &:active {
                border-color: @border-inverse-active;
            }

            &,
            &[disabled],
            &.disabled {
                @default-styles();
            }
        }
    }
}

// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.
.form-control-validation(@text-color: #555; @border-color: #ccc; @background-color: #f5f5f5) {
    // Color the label and help text
    .help-block.help-block--error,
    &.help-block.help-block--error,
    .control-label,
    &.control-label,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline,
    .Select .Select-placeholder {
        color: @text-color;
    }
    // Set the border and box shadow on specific inputs to match
    .form-control {
        border-color: @border-color;
        box-shadow: none;
        &:focus {
            box-shadow: 0 0 0 2px fade(@border-color, 30%);
        }
    }
    // Set validation states also for addons
    .input-group-addon {
        color: white;
        border-color: @border-color;
        background-color: @background-color;
    }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

.form-control-focus(@color: @input-border-focus) {
    @color-rgba: rgba(red(@color), green(@color), blue(@color), 0.6);
    &:focus {
        border-color: @color;
        outline: 0;
        .box-shadow(~'0 0 3px @{color-rgba}');
    }
}

.default-focus-outline(@color: @brand-primary) {
    .focus-outline(1px solid @color, 2px);
}

// Tables
// -------------------------
.table-row-variant(@state; @background; @border) {
    // Exact selectors below required to override `.table-striped` and prevent
    // inheritance to nested tables.
    .table > thead > tr,
    .table > tbody > tr,
    .table > tfoot > tr {
        > td.@{state},
        > th.@{state},
        &.@{state} > td,
        &.@{state} > th {
            background-color: @background;
            color: darken(@background, 55%);
        }
    }

    // Hover states for `.table-hover`
    // Note: this is not available for cells or rows within `thead` or `tfoot`.
    .table-hover > tbody > tr {
        > td.@{state}:hover,
        > th.@{state}:hover,
        &.@{state}:hover > td,
        &.@{state}:hover > th {
            background-color: darken(@background, 5%);
        }
    }
}

.table__row--clickable {
    cursor: pointer;

    &:focus {
        outline: 1px solid @brand-primary;
        outline-offset: -1px;
    }
}

// Generate margin classes (like .top-20)
.make-margin-class(@direction, @margin) {
    @unit: unit(@margin);
    .@{direction}-@{unit} {
        margin-@{direction}: @margin;
    }
}
.make-margin-class(@direction, @margin, @breakpoint) {
    @breakpointValue: 'screen-@{breakpoint}-min';
    @unit: unit(@margin);

    @media (min-width: @@breakpointValue) {
        .@{direction}-@{breakpoint}-@{unit} {
            margin-@{direction}: @margin;
        }
    }
}

// Generate hidden classes (like .hidden-max200)
.make-hidden-min(@min-width) {
    @unit: unit(@min-width);

    @media (min-width: @min-width) {
        .hidden-min@{unit} {
            .responsive-invisibility();
        }
    }
}

.make-hidden-max(@max-width) {
    @unit: unit(@max-width);

    @media (max-width: @max-width) {
        .hidden-max@{unit} {
            .responsive-invisibility();
        }
    }
}

// Generate column classes (like .col-max560-12)
.make-column-max(@max-width, @columns) {
    @unit: unit(@max-width);

    @media (max-width: @max-width) {
        .col-max@{unit}-@{columns} {
            width: percentage((@columns / @grid-columns));
        }
    }
}

.flex-layout() {
    &:extend(.flex);
}
