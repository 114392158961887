.jw-image-text .jw-element-imagetext-text {
    //don't set max-width:100%. It gives the contenteditable div resize handles in IE

    img {
        height: auto;
    }

    img,
    table,
    object,
    iframe {
        max-width: 100%;
    }
}

// Remove margin-top from headings if they directly follow a Separator or Spacer element
.jw-separator,
.jw-spacer {
    & + .jw-image-text .jw-element-imagetext-text {
        & > *:first-child {
            margin-top: 0;
        }
    }
}
