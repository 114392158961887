@jw-news-post-margin: 1em;
@jw-news-post-padding: 1em;

.jw-element-news-content {
    position: relative;
}

/* NEWS */
.jw-news-posts {
    display: flex;
    flex-direction: column;
}

.jw-news-pagination {
    margin-top: @jw-news-post-margin;
}

/* NEWS HEADING */
.jw-news-heading {
    display: none;

    padding: @jw-news-post-padding * 0.3 @jw-news-post-padding;

    .jw-news-post ~ & {
        margin-top: @jw-news-post-margin;
    }
}

.jw-news-posts--sort-day {
    .jw-news-heading-day {
        display: block;
    }
}
.jw-news-posts--sort-week {
    .jw-news-heading-week {
        display: block;
    }
}
.jw-news-posts--sort-month {
    .jw-news-heading-month {
        display: block;
    }
}

.jw-news-post__more {
    display: none;
}

/* NEWS POST */
.jw-news-post {
    position: relative;
    box-sizing: border-box;

    .jw-news-post__title {
        margin: 0;
        padding: 0;
        background: none;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .jw-news-post__meta {
        margin: 0;
    }
}

.jw-news-post__block-link {
    display: none;
}

.jw-news-post__image {
    display: none;

    > div {
        width: 100%;
        height: 100%;

        background-position: center center;
        background-size: cover;
    }

    &--size-small,
    &--size-large,
    &--size-above {
        display: block;
    }
}

.jw-news-post__lead {
    display: none;

    .jw-news-post--lead-short &--short {
        display: block;
    }

    .jw-news-post--lead-long &--long {
        display: block;
    }

    .jw-news-post--lead-longer &--longer {
        display: block;
    }
}

/* VIEW: LIST */
.jw-news-posts--list {
    .jw-news-post__image > div {
        padding-bottom: 0 !important;
    }

    .jw-news-heading {
        padding: 0.1 * @jw-news-post-padding 0.5 * @jw-news-post-padding;
    }

    .jw-news-post {
        padding: 0.3 * @jw-news-post-padding 0;
    }

    .jw-news-post + .jw-news-post {
        border-top-width: 1px;
        border-top-style: solid;
    }

    &.jw-news-posts--sort-day,
    &.jw-news-posts--sort-week,
    &.jw-news-posts--sort-month {
        .jw-news-post {
            padding: 0.3 * @jw-news-post-padding 0.5 * @jw-news-post-padding;
        }
    }

    .jw-news-post__lead {
        display: none;
    }

    .jw-news-post__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    .jw-news-post__title {
        font-size: 1em;
    }
    .jw-news-post__meta {
        margin-left: @jw-news-post-padding;
        white-space: nowrap;
        opacity: 0.8;
    }
}

/* VIEW: PARAGRAPH */
.jw-news-posts--paragraph {
    .jw-news-post {
        padding: 0 0 @jw-news-post-margin;
        &:last-child {
            padding-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    & .jw-news-post--more-link-visible .jw-news-post__more {
        display: inline-block;
    }

    & .jw-news-post--background-color {
        padding: 0 0 @jw-news-post-margin @jw-news-post-padding;
        &:last-child {
            padding-bottom: 0;
        }
    }

    & .jw-news-post__image--cropped > div {
        height: auto;

        // Important necessary; because by default the height of the image is set using inline styling
        padding-bottom: (100% / 3) * 2 !important;
    }

    & .jw-news-post__image--size-above.jw-news-post__image--cropped > div {
        padding-bottom: (100% / 3) !important;
    }

    & .jw-news-post__image--size-small,
    & .jw-news-post__image--size-large {
        position: relative;
        z-index: 1;
        float: right;

        margin: 0 0 0 @jw-news-post-padding;
        width: 300px;
        max-width: 30%;
    }

    .jw-news-post__image--size-above {
        margin: 0 0 @jw-news-post-padding;
    }

    .jw-news-post__image--size-small {
        width: 200px;
        max-width: 20%;

        .lt400 & {
            float: none;
            margin: 0 0 @jw-news-post-padding;
            width: 100%;
            max-width: 100%;
        }
    }

    .jw-news-post__image--size-large {
        width: 300px;
        max-width: 30%;

        .lt600 & {
            float: none;
            margin: 0 0 @jw-news-post-padding;
            width: 100%;
            max-width: 100%;
        }
    }

    .jw-news-post__header {
        margin-bottom: 0.5 * @jw-news-post-padding;
    }
    .jw-news-post__meta {
        opacity: 0.8;
    }
}

/* VIEW: GRID */
.jw-news-posts--grid {
    flex-flow: row wrap;
    margin: @jw-news-post-margin * -0.5;

    .jw-news-post__image > div {
        padding-bottom: 0 !important;
    }

    .jw-news-heading {
        width: 100%;
        margin: 0.5 * @jw-news-post-margin 0.5 * @jw-news-post-margin;
    }

    .jw-news-post ~ .jw-news-heading {
        margin-top: 1.5 * @jw-news-post-margin;
    }

    .jw-news-post {
        .grid-item(@row-size) {
            @percentage: round((100% / @row-size), 2);
            @total-margin: @jw-news-post-margin;
            flex-basis: ~'calc(@{percentage} - @{total-margin})';
        }

        .grid-item(3);
        .lt800 & {
            .grid-item(2);
        }
        .lt400 & {
            .grid-item(1);
        }

        margin: @jw-news-post-margin * 0.5;

        border-width: 1px;
        border-style: solid;
        // border-color: <defined based on template>;

        &::before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }

    .jw-news-post__lead {
        display: none;
    }

    .jw-news-post__block-link,
    .jw-news-post__image {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -1px; // Compensate for border on parent
    }

    .jw-news-post__block-link {
        z-index: 2;
    }

    .jw-news-post__header {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        overflow: hidden;

        padding: @jw-news-post-padding;
        margin: -1px; // Compensate for border on parent

        box-sizing: border-box;
        max-height: ~'calc(100% + 2px)'; // 100% + margin
    }

    .jw-news-post__title {
        flex: 0 1 auto;
        order: 2;
    }

    .jw-news-post__meta {
        order: 1;
    }

    // Add hover effect to title link when hovering over the block link
    .jw-news-post__block-link:hover
        ~ .jw-news-post__header
        .jw-news-post__title
        a {
        text-decoration: underline;
    }

    .jw-news-post--has-image {
        .jw-news-post__header {
            color: white;
            text-shadow: 0 1px 2px fade(black, 20%);

            background: linear-gradient(
                to top,
                fade(black, 50%) 0%,
                fade(black, 25%) 30%,
                transparent 100%
            );

            &::after {
                content: '';
                flex: 0 100 4em;
            }
        }
        .jw-news-post__title,
        .jw-news-post__title a {
            color: inherit;
        }
    }
}

/* NEWS PAGE */
.jw-news-page__heading-without-margin {
    margin-bottom: 0.1em !important;
    padding-bottom: 0 !important;
}

.jw-news-page__meta {
    opacity: 0.8;
    // Using padding instead of margin, to make sure the background is not affected.
    padding-bottom: 1em;
    margin-bottom: 0;
}

.jw-news-page-pagination {
    display: flex;
    margin: 0 0 2em;

    &__link {
        flex: 0 1 auto;
        display: flex;
        min-width: 0;
        max-width: 45%;

        &--next {
            margin-left: auto;
        }

        // Prev/next text
        span {
            flex: 0 0 auto;

            &::before,
            &::after {
                white-space: pre;
            }
        }

        // Title of prev/next post
        em {
            flex: 0 1 auto;
            min-width: 0;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

// TODO#strips: temporary fix. Remove top/bottom padding on default of the first and last
// default strips of the post content, because otherwise the spacing looks weird.
// We could restructure the breadcrumbs/heading/meta to render inside the first strip (aka a lot of work).
.jw-is-strips .news-page-content-container {
    .jw-strip--color-default.jw-node-is-first-child {
        padding-top: 0;
    }
    .jw-strip--color-default.jw-node-is-last-child {
        padding-bottom: 0;
    }
}
