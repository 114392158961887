.jw-search-input {
    width: 100%;
    padding: 0.25em 0.5em;
    padding-right: 35px;
}

.jw-search-submit {
    position: absolute;
    padding: 0.25em;
    right: 0;
    top: 0;

    background: none;
    border: none;
}

.jw-search-results {
    margin-top: 1em;
}

.jw-search-result {
    h3,
    h3 :not(mark) {
        // search result h3s may not be bold in order to make the highlighted text stand out
        font-weight: normal !important;
    }

    mark {
        background: none;
        font-weight: bold;
        color: inherit;
    }
}
