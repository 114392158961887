@product-gallery-transition: 300ms;

.product-gallery {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--empty {
        padding: 20px;
        justify-content: center;
        align-items: center;
    }
}

.product-gallery__item {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    flex: 1 0 auto;

    // Remove intrinsic width, so items with long names don't become too large
    min-width: 0;
}

.product-gallery__content {
    position: relative;
    z-index: 0;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    transition:
        z-index @product-gallery-transition ease,
        box-shadow @product-gallery-transition ease,
        border-color @product-gallery-transition ease,
        background @product-gallery-transition ease,
        padding @product-gallery-transition ease,
        margin @product-gallery-transition ease;

    &:hover,
    &:focus-within {
        z-index: 100;
    }
}

.product-gallery .product-sticker {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 3; // Has to draw over .gallery-item__image at index 2.

    margin: 0;
}

.product-gallery__image-container {
    position: relative;

    // Make the image-container square
    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .make-aspect-ratio-modifier(@w, @h) {
        .product-gallery--image-aspect-@{w}x@{h} &::before {
            padding-top: (100% / @w) * @h;
        }
    }
    .make-aspect-ratio-modifier(4, 3); // Horizontal
    .make-aspect-ratio-modifier(3, 2); // Horizontal
    .make-aspect-ratio-modifier(1, 1); // Square
    .make-aspect-ratio-modifier(2, 3); // Vertical
    .make-aspect-ratio-modifier(3, 4); // Vertical
}

.product-gallery__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .product-image {
        width: 100%;
        height: 100%;

        &__image {
            // object-fit _shouldn't_ be necessary, but Safari seems to have some problems with
            // uncached lazy-loaded images _sometimes_
            // https://github.com/Webador/jouwweb/issues/9177
            object-fit: contain;

            // min-width _shouldn't_ be necessary, but Safari seems to have some problems with
            // uncached lazy-loaded images in the Editor
            // https://github.com/Webador/jouwweb/issues/11045
            min-width: 1px;

            .product-gallery--image-fit-cover & {
                object-fit: cover;
            }
        }
    }
}

.product-gallery__image--placeholder {
    .product-image__image {
        &,
        .product-gallery--image-fit-cover & {
            // Icon/text should never scale up. The image has a CSS background,
            // so the background will still scale according to the width/height.
            object-fit: scale-down;
        }
    }
}

.product-gallery__info-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.product-gallery__details {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    text-align: center;
    line-height: 1.4em;
}

.product-gallery__name {
    flex: 1 0 auto;
    max-width: 100%;

    font-size: 1.2em;

    // Override default <a> styles
    color: inherit;
    text-decoration: none;
    a&:hover {
        text-decoration: underline;
    }
}

.product-gallery__price {
    padding-top: 5px;

    s {
        font-size: 0.8em;
        line-height: 1.6em;
        vertical-align: top;

        opacity: 0.75;
    }
}

.product-gallery__expand-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .product-gallery__item--has-button &,
    .product-gallery__item--has-options & {
        margin-top: 15px;
    }

    button,
    select {
        // Position on top of anchor
        position: relative;
        z-index: 2;
    }

    select {
        margin-bottom: 10px;
        width: 100%;
        max-width: 100%;
    }

    .product__variants {
        margin: 0;
    }
}

.product-gallery__button-container {
    display: flex;
    flex-direction: row;
    max-width: 100%;

    button + button {
        margin-left: 0.3em;
    }

    .product__add-to-cart {
        min-width: 0;
    }

    .product__add-to-cart > span {
        // Prevent button text from wrapping
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product__add-to-wishlist {
        flex: 0 0 auto;
    }
}

// SIZE: sm / md / lg / xl

.product-gallery--size-sm {
    .product-gallery__item {
        flex-basis: (100% / 6);

        .lt800 & {
            flex-basis: (100% / 4);
        }
        .lt600 & {
            flex-basis: (100% / 3);
        }
        .lt480 & {
            flex-basis: (100% / 2);

            .product__add-to-cart__icon {
                display: block !important;
            }
        }
    }
}

.product-gallery--size-md {
    .product-gallery__item {
        flex-basis: (100% / 4);

        .lt800 & {
            flex-basis: (100% / 3);
        }
        .lt540 & {
            flex-basis: (100% / 2);

            .product__add-to-cart__label:not(:only-child) {
                display: none;
            }
            .product__add-to-cart__icon {
                display: block !important;
            }
        }
    }
}

.product-gallery--size-lg {
    .product-gallery__item {
        flex-basis: (100% / 3);

        .lt600 & {
            flex-basis: (100% / 2);
        }
        .lt400 & {
            flex-basis: (100% / 1);
        }
    }
}

.product-gallery--size-xl {
    .product-gallery__item {
        flex-basis: (100% / 2);

        .lt540 & {
            flex-basis: (100% / 1);
        }
    }
}

// ALIGN: left / center / right
.product-gallery--align-left {
    .product-gallery__details,
    .product-gallery__expand-container {
        text-align: left;
        align-items: flex-start;
    }
}
.product-gallery--align-center {
    .product-gallery__details,
    .product-gallery__expand-container {
        text-align: center;
        align-items: center;
    }
}
.product-gallery--align-right {
    .product-gallery__details,
    .product-gallery__expand-container {
        text-align: right;
        align-items: flex-end;
    }
}

// STYLE: Bordered / Clean / Image only
@product-gallery-item-padding: 15px;
@product-gallery-item-border-width: 1px;

.product-gallery--style-bordered {
    // Compensate for negative margin on gallery items
    padding: @product-gallery-item-border-width 0 0
        @product-gallery-item-border-width;

    .product-gallery__content {
        flex: 1 0 auto;
        padding: @product-gallery-item-padding;

        // Collapse borders
        margin-top: -@product-gallery-item-border-width;
        margin-left: -@product-gallery-item-border-width;

        border: @product-gallery-item-border-width solid;
    }

    .product-sticker {
        right: -@product-gallery-item-padding;
    }
}

.product-gallery--style-clean {
    @columns-spacing: 24px; // Horizontal spacing between container columns

    // Compute alternative padding so product images are aligned with columns
    // from the container element
    @border-width: @product-gallery-item-border-width;
    @padding: (@columns-spacing / 2) - @border-width;
    @padding-hover: @product-gallery-item-padding;

    // Compensate for padding and border at the sides, so product images are
    // aligned "flush" with the container
    margin: -(@padding + @border-width);

    .product-gallery__item {
        // Prevent product-gallery__item overlapping product filter
        pointer-events: none;
    }

    .product-gallery__content {
        // padding-bottom starts at the :hover-value to avoid duplicating the
        // margin logic for the expanding button effect of --style-bordered
        padding: @padding @padding @padding-hover;

        border: @border-width solid transparent;
        background-color: transparent;

        &:hover,
        &:focus-within {
            // Expand to full padding on hover
            padding: @padding-hover;

            // Compensate for added padding, so overall spacing does not change
            margin: @padding - @padding-hover;
            margin-bottom: 0;
        }

        // Disable pointer-events for the invisible border...
        pointer-events: none;
        & > * {
            pointer-events: all;
        }
        // ... but re-enable once it becomes visible again
        &:hover,
        &:focus-within {
            pointer-events: all;
        }
    }
}

.product-gallery--style-bordered,
.product-gallery--style-clean {
    .product-gallery__content {
        &:hover,
        &:focus-within {
            box-shadow:
                0 2px 4px fade(#000, 12%),
                0 0 10px fade(#000, 8%);
        }
    }

    .product-gallery__details {
        margin-bottom: auto;
    }

    // Hide cart button
    .product-gallery__expand-container {
        visibility: hidden;
        opacity: 0;

        transition:
            margin @product-gallery-transition ease,
            opacity (@product-gallery-transition * 1/3) ease,
            visibility @product-gallery-transition ease;
    }

    .product-gallery__content:hover .product-gallery__expand-container,
    .product-gallery__content:focus-within .product-gallery__expand-container {
        visibility: visible;
        opacity: 1;

        transition:
            margin @product-gallery-transition ease,
            opacity @product-gallery-transition ease
                (@product-gallery-transition * 1/3),
            visibility @product-gallery-transition ease;
    }
}

.product-gallery--style-image-only {
    @columns-spacing: 24px; // Horizontal spacing between container columns
    @margin: (@columns-spacing / 2);

    // Compensate for added margins on __content
    margin: -@margin;

    .product-gallery__item {
        // Prevent product-gallery__item overlapping product filter
        pointer-events: none;
        & > .product-gallery__content {
            pointer-events: all;
        }
    }

    .product-gallery__content {
        margin: @margin;

        &:hover,
        &:focus-within {
            .product-gallery__info-container {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    // Open overlay when image is tapped, instead of following the image link
    .jw-is-touch-device & .product-gallery__image-container a {
        pointer-events: none;
    }

    .product-gallery__info-container {
        position: absolute;
        z-index: 1;
        top: 0;

        display: flex;
        flex-direction: column;

        // Known bug: in IE11 the content will not be vertically centered. This
        // happens due to a bug with flex contains and min-height.
        // See https://github.com/philipwalton/flexbugs#flexbug-3
        justify-content: center;

        width: 100%;
        min-height: 100%;
        padding: 8%;
        box-sizing: border-box;

        opacity: 0;
        visibility: hidden;

        transition:
            opacity @product-gallery-transition ease,
            visibility @product-gallery-transition ease;
    }

    .product-gallery__details {
        flex: 0 0 auto;
    }
}

// HEADER STUFF

.product-gallery-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.25em;

    .product-gallery-results {
        flex: 1 1 auto;
        margin-right: 0.5em;

        // In the rare case that there is _not_ enough space (product gallery in a column on a weird tablet), this will
        // still make it fit
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .product-gallery-sorting-label {
        flex: 0 0 auto;
        margin-right: 0.5em;

        .lt400 & {
            display: none;
        }
    }
    .product-gallery-sorting {
        flex: 0 0 auto;
    }
}

.product-gallery-filter {
    position: relative;
    margin-right: 20px;
    white-space: nowrap;

    &__button {
        padding: 0.25em 0;
        background: none;
        border: none;

        font: inherit;

        &:hover {
            text-decoration: underline;
        }

        .default-focus-outline();

        &--active {
            font-weight: bold;
        }
    }

    .jw-popover {
        &__content {
            width: auto !important;
            max-width: 15em;
        }
    }

    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0;

        legend {
            .sr-only();
        }
    }
}

.filter-option {
    display: flex;
    align-items: center;

    margin-bottom: 0.5em;
    line-height: 1;

    input[type='checkbox'] {
        flex: 0 0 auto;
        min-width: 1.125em;
        min-height: 1.125em;
        margin-right: 0.5em;
    }

    &__label {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__count {
        display: inline-block;
        margin-left: 0.25em;
    }
}
