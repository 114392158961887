.jw-table {
    table {
        width: 100%;
    }
    th {
        text-align: left;
    }
    td,
    th {
        padding: 0.4em;
    }
}

.jw-table--flat {
    td,
    th {
        padding: 0.2em;
    }
}

.responsive-table-container {
    overflow-x: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px transparent;
    }
}

// Table was designed on desktop and will most likely not be look as well on mobile. Therefore ignore
// widths on small devices; and let the browser figure out the best way to show the table.
@media (max-width: 768px) {
    .jw-table th,
    .jw-table td {
        width: auto;
    }
}

.handsontable th,
.handsontable td {
    color: #000;
}

.handsontable {
    .jw-table--header {
        tr:first-child td {
            font-weight: bold;
        }
    }
}

.handsontableInputHolder .handsontableInput {
    box-shadow: 0 0 0 2px #5292f7 inset, 0 2px 5px rgba(0, 0, 0, 0.4);
}

.table-tooltip {
    padding: 0 4px 0 4px;
}

.lt600 {
    .table-tooltip {
        display: none;
    }
}
