@jw-album-spacing-cozy: (@element-gap-block / 4);
@jw-album-spacing-compact: (@element-gap-block / 2);
@jw-album-spacing-comfortable: (@element-gap-block);

.jw-album {
    display: flex;
    flex-wrap: wrap;
}

.jw-album__pusher {
    // Pusher is hidden by default, since it is only needed in the masonry layout
    display: none;
}

.jw-album--display-format-masonry .jw-album__pusher {
    display: block;
    flex-grow: 1000;
}

// ITEM SPACING
.jw-album-spacing(@spacing) {
    margin: -(@spacing / 2);

    // Display formats 'contain' and 'cover' manage item size by setting `width`
    // to a certain percentage of the container width. So the item spacing must
    // use padding, since margins would increase the total width beyond the
    // container width.
    .jw-album-image {
        padding: (@spacing / 2);
    }

    // For display format 'masonry', the dimensions of the items are managed by
    // flexbox, which correctly handles margins. If we were to use padding here,
    // the aspect-ratio would not be maintained correctly in responsive settings.
    &.jw-album--display-format-masonry .jw-album-image {
        padding: 0;
        margin: (@spacing / 2);
    }
}

.jw-album--spacing-cozy {
    .jw-album-spacing(@jw-album-spacing-cozy);
}
.jw-album--spacing-compact {
    .jw-album-spacing(@jw-album-spacing-compact);
}
.jw-album--spacing-comfortable {
    .jw-album-spacing(@jw-album-spacing-comfortable);
}

// IMAGE
.jw-album-image {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
}

.jw-album-image__inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    line-height: 0;
}

.jw-album-image__aspect {
    display: none;
}

.jw-album-image__image {
    // This is required for lazyload to properly work in all cases (https://github.com/Webador/jouwweb/issues/6294)
    min-width: 1px;
    min-height: 1px;
}

.jw-album-image__caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.5em;

    color: white;
    background: fade(black, 50%);

    line-height: normal;
    text-align: center;

    opacity: 0;
    transform: translate(0, 100%);
    transition:
        opacity 200ms ease,
        transform 200ms ease;

    .jw-album-image:hover &,
    .jw-album-image:focus & {
        opacity: 1;
        transform: translate(0, 0);
    }

    .jw-album--no-captions & {
        display: none;
    }
}

// DISPLAY FORMATS
.jw-album--display-format-cover,
.jw-album--display-format-contain .jw-album-image--source-portrait {
    .jw-album-image__aspect {
        display: block;
        padding-bottom: 100% !important;
    }

    .jw-album-image__image {
        position: absolute;

        // Fallback for browsers without object-fit support
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.jw-album--display-format-cover {
    // Fallback for browsers without object-fit support
    .jw-album-image--source-portrait {
        .jw-album-image__image {
            width: 100%;
            height: auto;
        }
    }
    .jw-album-image--source-landscape {
        .jw-album-image__image {
            width: auto;
            height: 100%;
        }
    }

    .jw-album-image--source-square {
        .jw-album-image__image {
            width: 100%;
            height: 100%;
        }
    }

    // Use object-fit where possible, since it produces cleaner results
    @supports (object-fit: cover) {
        .jw-album-image .jw-album-image__image {
            top: 0;
            left: 0;
            transform: none;

            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }
}

.jw-album--display-format-contain {
    .jw-album-image__image {
        max-width: 100%;
        max-height: 100%;
    }

    // Use object-fit where possible, since it produces cleaner results
    @supports (object-fit: scale-down) {
        .jw-album-image--source-portrait .jw-album-image__image {
            top: 0;
            left: 0;
            transform: none;

            width: 100%;
            height: 100%;

            object-fit: scale-down;
        }
    }
}

.jw-album--display-format-masonry {
    .jw-album-image__aspect {
        display: block;
        // padding-bottom: (set by backend code based on actual aspect-ratio of the image)
    }

    .jw-album-image__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

// IMAGE SIZES
.jw-album-image-size(@divider) {
    @size: round((100% / @divider), 4);
    .jw-album-image {
        // Note: width is overridden in the 'masonry' display-format
        width: @size;
    }
}

.jw-album--size-small {
    .jw-album-image-size(6);
}

.lt600 .jw-album--size-small,
.jw-album--size-medium {
    .jw-album-image-size(4);
}

.lt400 .jw-album--size-small,
.lt600 .jw-album--size-medium,
.jw-album--size-large {
    .jw-album-image-size(3);
}

.lt400 .jw-album--size-medium,
.lt600 .jw-album--size-large {
    .jw-album-image-size(2);
}

.lt400 .jw-album--size-large {
    .jw-album-image-size(1);
}

// PAGINATION
.jw-album-pagination {
    margin-top: 1em;
}

.jw-album-pagination-link {
    &.is-active {
        font-weight: bold;
    }
}
