.ui-tooltip,
.tooltip,
.floating-tooltip {
    z-index: 999999 !important;
}

.stack-bottomright {
    top: auto !important;
    bottom: 25px !important;
}
