@jw-social-follow-sizes: {
    s: 24px;
    m: 32px;
    l: 48px;
    xl: 75px;
};

/**
 * Update @x to match the parity of @y. For example: .match-parity(5, 12)[@result] = 6.
 * @result is the value of @x changed to match the parity of @y
 */
.match-parity(@x, @y) when not (mod(@x, 2) = mod(@y, 2)) {
    @result: @x + 1;
}
.match-parity(@x, @y) when (default()) {
    @result: @x;
}

// Sizing
each(@jw-social-follow-sizes, {
    @breakpoint: @key;
    @size: @value;
    @margin: floor(@size * 0.125);

    .jw-element-social-follow--size-@{breakpoint} {
        // .match-parity is used to make sure there is equal space above and below the icon
        @font-size: .match-parity(round((@size / 1.5)), @size)[@result];
        @padding-v: ((@size - @font-size) / 2);

        @font-size-round: .match-parity(round((@size / 1.7)), @size)[@result];
        @padding-v-round: ((@size - @font-size-round) / 2);

        @font-size-icononly: .match-parity(round((@size / 1.3)), @size)[@result];

        margin: -@margin;

        .jw-element-social-follow-profile {
            width: @size;
            height: @size;

            margin: @margin;

            font-size: @font-size;
            line-height: @size;
        }

        &.jw-element-social-follow--roundness-round {
            .jw-element-social-follow-profile {
                font-size: @font-size-round;
            }
        }

        &.jw-element-social-follow--style-icononly {
            .jw-element-social-follow-profile {
                font-size: @font-size-icononly;
            }
        }

        // Special case: Facebook icon extends to bottom, so increase its size
        &.jw-element-social-follow--style-solid {
            &.jw-element-social-follow--roundness-square,
            &.jw-element-social-follow--roundness-rounded {
                .jw-element-social-follow-profile--medium-facebook {
                    font-size: @font-size + @padding-v;
                }
            }
            &.jw-element-social-follow--roundness-round {
                .jw-element-social-follow-profile--medium-facebook {
                    font-size: @font-size-round + @padding-v-round;
                }
            }
        }
    }
});

.jw-element-social-follow-profile {
    // Allow setting alignment
    display: inline-block;
    vertical-align: top;
    overflow: hidden;

    text-decoration: none !important;

    transition: opacity 150ms ease;

    .default-focus-outline();

    &:hover,
    &:focus {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.7;
    }

    // Center the icon
    [class^='website-rendering-icon'] {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        &::before {
            width: 100%;
            text-align: center;
            margin: 0;
        }
    }

    &.is-hidden {
        display: none;
    }

    // STYLE
    .jw-element-social-follow--style-border & {
        border-width: 2px;
        border-style: solid;
    }
    .jw-element-social-folow--style-icononly & {
        background: none !important;
    }

    // ROUNDNESS
    .jw-element-social-follow--roundness-rounded & {
        border-radius: 0.15em;
    }
    .jw-element-social-follow--roundness-round & {
        border-radius: 50%;
    }
}

// Special case: Facebook icon extends to the bottom
.jw-element-social-follow--style-solid {
    .website-rendering-icon-facebook::before {
        // Align icon to bottom
        margin-top: auto;
        margin-bottom: -1px;
    }

    &.jw-element-social-follow--roundness-square,
    &.jw-element-social-follow--roundness-rounded {
        .website-rendering-icon-facebook::before {
            // Offset icon to the right
            margin-left: 0.15em;
        }
    }
}

// Using brand colors
.jw-element-social-follow--color-brand {
    &.jw-element-social-follow--style-solid {
        .jw-element-social-follow-profile {
            color: white;

            // Special case: fancy Instagram background
            &--medium-instagram {
                background: url('~assets/img/instagram-background.svg') center;
                background-size: cover;
            }

            // Special case: fancy TikTok shadow (only an approximation)
            &--medium-tiktok {
                @offset: 0.05em;
                text-shadow:
                    -@offset -@offset #69c9d0,
                    @offset @offset #ee1d52;
            }
        }
    }
}

.jw-element-social-follow {
    .jw-element-content {
        display: inline-block;
    }
    &.is-empty > .jw-element-content {
        display: none;
    }
}

.jw-element-social-follow-placeholder {
    display: none;

    .jw-element-social-follow.is-empty & {
        display: block;
    }
}
