.jw-element.jw-map {
    word-wrap: normal;
}

.jw-map--overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.pac-container {
    background-color: #373f43;
    color: #e4e3df;
    position: absolute !important;
    z-index: 1500; // jw-mobile-menu is on 1400 and overlaps
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;

    &:after {
        content: '';
        padding: 1px 1px 1px 0;
        height: 16px;
        text-align: right;
        display: block;
        background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white2.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: 104px 16px;
    }
}

.hdpi.pac-container:after {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white2_hdpi.png);
}

.pac-item {
    cursor: default;
    padding: 0 9px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #54595a;
    font-size: 11px;
    color: #e4e3df;

    &:hover {
        background-color: #2c3235;
    }
}

.pac-item-selected,
.pac-item-selected:hover {
    background-color: #2c3235;
}

.pac-matched {
    font-weight: 700;
}

.pac-item-query {
    font-size: 13px;
    padding-right: 3px;
    color: #e4e3df;
}

.pac-icon {
    width: 0;
    height: 0;
    margin-right: 0;
    margin-top: 0;
    display: inline-block;
    vertical-align: top;
    background-image: none;
}

.hdpi .pac-icon {
    background-image: none;
}

.pac-placeholder {
    color: gray;
}

@media screen and (max-width: @screen-xs-max) {
    // Add max width for map location input on smaller devices to keep it on one line
    input#location {
        max-width: 215px;
    }
}
