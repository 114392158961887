@column-gap: 50px;
@column-gap-half: (@column-gap / 2);

@column-gap-lt800: 25px;
@column-gap-half-lt800: (@column-gap-lt800 / 2);

.product-page {
    position: relative;

    margin-bottom: @element-gap-block;

    .clearfix();
}

// Layout
.product-page__top,
.product-page__image-container,
.product-page__container {
    box-sizing: border-box;

    width: calc(50% - @column-gap-half);

    .lt800 & {
        width: calc(50% - @column-gap-half-lt800);
    }

    .lt600 & {
        float: none;
        width: 100%;
    }

    .product-page--no-images & {
        float: none;
        width: 100%;
    }
}

.product-page__image-container {
    float: left;
}

.product-page__top,
.product-page__container {
    float: right;
}

.product-page__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    row-gap: 10px;
    column-gap: 20px;
    margin: 0 0 2em;

    .product-sticker {
        float: none;
        margin: 0;
    }
}

.product-page__heading {
    overflow-wrap: break-word;
    max-width: 100%;

    // duplicated selector to increase specificity over `.jw-section h1`
    &.product-page__heading {
        margin: 0;
    }
}

.product-page__image-container {
    min-height: 100px;
    margin-bottom: 2em;
}

.product-page__container {
    margin-bottom: 2em;
}

.product-page__price-container,
.product-page__customization-container,
.product-page__button-container {
    margin-bottom: 2em;
}

.product-page {
    .product__price {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;

        font-size: 2em;
        line-height: 1.3em;

        &__price {
            order: 1;

            font-weight: 700;
        }
        &__old-price {
            order: 2;
            margin-left: 0.5em;

            font-size: 0.75em;
            opacity: 1;
        }
        &-disclaimer {
            width: 100%;
            order: 3;
            font-size: 0.45em; // Effectively 0.9em of normal font-size
        }
    }

    .product__free-shipping-motivator {
        display: block;
        margin-top: 5px;

        &:empty {
            display: none;
        }
    }
}

.product-page__customization-container {
    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.product-page__button-container {
    display: flex;
    flex-wrap: nowrap;

    & > * {
        margin: 0 0 0 0.5em;

        &:first-child {
            margin-left: 0;
        }
    }

    .lt600 & {
        .product__add-to-cart {
            flex-grow: 1;
        }
    }
}

.product-page__button-notice {
    width: 100%;
    // margin-top: 0.5em - [margin-bottom of .product-page__button-container]
    margin: -1.5em 0 2em;

    color: @jw-element-form-error;
}

.product-page {
    .product-quantity-input {
        margin: 0;

        &__label {
            display: none !important;
        }

        > label {
            display: flex;
            height: 100%;
        }

        &__input,
        &__select {
            padding-right: 0;
            width: 4em !important;
            margin: 0;
        }
    }
}

.product-page__number-container {
    margin-bottom: @element-gap-block;
}

.product-page__description {
    margin-bottom: @element-gap-block;
    padding-top: @element-gap-block;
    border-top-width: 1px;
    border-top-style: solid;

    & > p:last-child {
        margin-bottom: 0;
    }

    // prevent issues with floats in descriptions
    .clearfix();
}

.product-page__social {
    // multiplier makes the margin look more equal to that of the description
    padding-top: @element-gap-block * 1.5;
    border-top-width: 1px;
    border-top-style: solid;
}
