.jw-spinner {
    display: inline-block;
    position: relative;

    width: 1em;
    height: 1em;

    &::before,
    &::after {
        content: '';
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        border: 0.15em solid currentColor;
        border-radius: 50%;
    }

    &::before {
        opacity: 0.3;
    }
    &::after {
        border-color: transparent;
        border-top-color: currentColor;

        @keyframes jw-spinner {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        animation: jw-spinner 0.8s linear infinite;
    }
}
