.jw-backend-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    & > * {
        pointer-events: auto;
    }
}

.is-picking .jw-section-content {
    > .jw-tree-container:not(.jw-tree-container--empty) {
        padding-bottom: 100px;
        transition: none;
    }
}

@drag-height: 19px;

// Remove
.jw-context-remove-wrap {
    position: absolute;
    z-index: @zindex-jw-context-buttons;

    max-height: 100%;

    display: flex;
    justify-content: flex-end;

    cursor: pointer;

    .jw-tree-empty-placeholder & {
        top: round((@drag-height / -2));
        right: round((@drag-height / -2));
    }

    .jw-is-touch-device & {
        padding: 0;
        top: @spacing-base;
        right: @spacing-base;
    }

    &.jw-context-remove-wrap-is-left {
        top: @spacing-base;
        left: @spacing-base;
        right: auto;
    }
}

// Utility to only show an item on the editor design page
.show-editor-is-design {
    display: none !important;

    .jw-is-design & {
        display: block !important;
    }
}
