@jw-credits-breakpoint: 500px;

.jw-tree-container:not(.jw-tree-container--empty) + .jw-credits {
    margin-top: 1em;
}

.jw-credits-owner {
    margin: 0;
    float: left;
    position: relative;
}

.jw-credits-right {
    float: right;
    position: relative;
}

.jw-footer-text-content {
    min-width: 1.4rem; // Allows editing of empty footer text. See https://github.com/Webador/jouwweb/issues/11421.
}

@media (max-width: @jw-credits-breakpoint) {
    .jw-credits-owner,
    .jw-credits-right {
        float: left;
        clear: both;
    }
}
