.jw-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99; // TODO
    user-select: none; // prevent interaction on overlay

    body.is-picking & {
        // Avoid interfering with drag-and-drop actions, since the overlay may
        // cover an element with `.jw-intent`.
        pointer-events: none;
    }
}

.jw-overlay.jw-overlay-is-movable {
    cursor: move;
}

.jw-overlay.jw-overlay--clickable {
    cursor: pointer;
}

.js-skip {
    pointer-events: none;
    user-select: none;
}
