@import (reference) '../layout.less';
@import (reference) './columns.less';

@column-resizer-width: 16px;

.jw-tree-node {
    position: relative;
    box-sizing: border-box;

    .jw-is-backend & {
        transition: margin 200ms ease;
    }
}

// Strip margin bottom of last non-image SimpleBrick in a parent
// TODO: I don't know why images are exempt. The behavior was originally implemented long
//       ago in https://github.com/Webador/jouwweb/commit/540e62d24d29fdc11e0f38ce21c74552cd27df92
//       but I'm not sure if there's still actually a use case for it.
//       https://github.com/Webador/jouwweb/pull/14298#discussion_r1494452084
.jw-node-is-last-child:not(.jw-tree-container, .jw-image) {
    .jw-layout-full({
        margin-bottom: 0 !important;
    });
}

// Show and hide resize handles using CSS. Removing the Resizable will also
// destruct the child resizables. IE: resizable of maps and image.
.jw-tree-horizontal {
    @handle-bg: rgba(lighten(@gray, 30%), 0.2);
    @handle-bg-hover: rgba(lighten(@gray, 25%), 0.3);
    @handle-color: rgba(lighten(@gray, 15%), 0.7);
    @handle-color-hover: rgba(lighten(@gray, 20%), 0.8);

    > .jw-tree-container > .ui-resizable-handle {
        visibility: hidden;
        opacity: 0;
    }

    &:hover > .jw-tree-container > .ui-resizable-handle,
    & > .ui-resizable-resizing > .ui-resizable-handle {
        body:not(.is-picking) & {
            visibility: visible;
            opacity: 1;
        }
    }

    > .jw-tree-container > .ui-resizable-handle {
        top: 0;
        bottom: 0;
        margin: 0;
        width: @column-resizer-width;
        height: auto;

        border: none;
        border-radius: 3px;
        background: @handle-bg;
        color: @handle-color;

        transition: background 200ms ease;

        &.ui-resizable-e {
            right: -(@column-resizer-width / 2);
        }

        &::before,
        &::after {
            top: 50%;
            left: 50%;
            width: 2px;
            height: 26px;
            max-height: 50%;

            background: currentColor;
            transform: translate(-50%, -50%);
        }

        &::before {
            margin-left: -2px;
        }

        &::after {
            margin-left: 2px;
        }
    }

    > .jw-tree-container.jw-columns__node--separator-right
        > .ui-resizable-handle {
        // Make room for separator remove button
        top: 20px;

        // Align with separator
        margin-right: (@columns-separator-width / -2);
    }

    > .jw-tree-container.ui-resizable-resizing > .ui-resizable-handle,
    > .jw-tree-container > .ui-resizable-handle:hover {
        background: @handle-bg-hover;
        color: @handle-color-hover;
    }
}
