.iconSizes() {
    .jw-image-is-flat {
        svg {
            width: 100%;
            height: 100%;
        }
    }

    .jw-image-is-square,
    .jw-image-is-rounded {
        svg {
            width: 80%;
            height: 80%;
            padding: 10%;
        }
    }

    .jw-image-is-round {
        svg {
            width: 50%;
            height: 50%;
            padding: 25%;
        }
    }
}

//create a stacking context for this element
//is needed because of the z-index which is set on the image
//SHOULD BE PORTED TO A GENERAL .jw-element-stacking class eventually
.jw-is-backend {
    .jw-image-text {
        .jw-element-image {
            z-index: 1; // Make sure image has higher z-index than editable part of TinyMce
        }

        .jw-element-button {
            z-index: @zindex-jw-element-buttons;
        }
        .jw-element-overlay {
            z-index: @zindex-jw-element-overlay;
        }
        .dropzone {
            z-index: @zindex-jw-element-overlay;
        }
        .jw-element-border {
            z-index: @zindex-jw-element-border;
        }
    }

    .jw-image-is-flat,
    .jw-image-is-square,
    .jw-image-is-rounded,
    .jw-image-is-round {
        position: relative;

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .iconSizes();
}

.iconSizes();

.jw-image-backend {
    width: 100%;
}

.jw-image-is-rounded,
.jw-image-is-round,
.jw-is-flat {
    overflow: hidden;
    display: inline-block;
}

.jw-image-is-rounded {
    border-radius: 0.4em;

    img {
        border-radius: 0.4em; // Necessary for Safari; see https://github.com/Webador/jouwweb/issues/5066
    }
}

.jw-image-is-round {
    border-radius: 50%;
    object-fit: cover;

    img {
        border-radius: 50%; // Necessary for Safari; see https://github.com/Webador/jouwweb/issues/5066
    }
}
