.jw-is-backend:not(.jw-is-mobile-view) {
    .share-button--mobile-only {
        opacity: 0.6;
    }
}

.jw-is-frontend.jw-is-no-mobile {
    .share-button--mobile-only {
        display: none;
    }
}

.jw-element-social-share {
    @button-height: 2em;
    @padding-h: 0.8em;
    @margin: 5px;

    .share-button-container {
        display: inline-flex;
        flex-wrap: wrap;
        margin-right: -@margin;

        .lt600 & {
            display: flex;
        }
    }

    .share-button-container--stacked {
        flex-direction: column;

        .share-button {
            width: auto;
        }
    }

    .share-button-container--align-left {
        justify-content: flex-start;
    }

    .share-button-container--align-center {
        justify-content: center;
    }

    .share-button-container--align-right {
        justify-content: flex-end;
    }

    .share-button {
        display: flex;
        margin-right: @margin;
        margin-bottom: @margin;
        padding-right: @padding-h;
        height: @button-height;
        box-sizing: border-box;
        line-height: @button-height;
        overflow: hidden;

        width: auto;

        .lt600 & {
            max-width: 200px;
            width: 32%;
            width: calc(~'33% -' @margin);
        }

        .lt400 & {
            width: 48%;
            width: calc(~'50% -' @margin);
        }

        .lt200 & {
            width: 100%;
        }

        text-decoration: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 3px;

        transition+: background-color 200ms ease;
        transition+: box-shadow 200ms ease;

        &:hover {
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
        }

        &:focus {
            outline: 1px solid currentcolor;
            outline-offset: 2px;
        }

        &:active {
            box-shadow:
                0 1px 4px transparent,
                inset 0 1px 2px rgba(0, 0, 0, 0.15);
        }
    }

    .share-button--wrapper {
        display: block;
        text-align: left;
        line-height: 0;
        padding: calc(@button-height / 2 - 11px);

        box-shadow: none !important;

        // Border color is overwritten on a template basis
        border: 1px solid rgba(0, 0, 0, 0.1);

        &.share-button--facebook-like {
            border: 0;
            padding: 1px 0 0 0;
        }
    }

    .share-button-container--buttons-mini .share-button,
    .share-button--mini {
        width: @button-height;
        padding: 0;

        .share-button__icon {
            background: transparent;
            margin: 0;
        }

        .share-button__label {
            display: none;
        }
    }

    .share-button__icon {
        @icon-size: 1.1em;

        flex: 0 0 auto;
        width: (@button-height / @icon-size);
        margin-right: (@padding-h / @icon-size);
        line-height: (@button-height / @icon-size);
        text-align: center;

        background: rgba(0, 0, 0, 0.1);
        font-size: @icon-size;
    }

    .share-button__label {
        flex: 1 0 auto;
        text-align: center;
        font-weight: bold;
    }

    .make-share-button(@name, @background, @text: #fff) {
        .share-button--@{name} {
            background-color: @background;
            text-decoration: none !important;

            // Set color to children only so `outline` can use
            // the sections’ color through `currentColor`.
            .share-button__label,
            .share-button__icon {
                color: @text !important;
            }

            &:hover {
                background-color: darken(@background, 5%);
            }

            &:active {
                background-color: darken(@background, 10%);
            }
        }
    }

    // Brand colors taken from http://brandcolors.net/
    .make-share-button(facebook, #3b5998);
    .make-share-button(twitter, lighten(#000, 10%));
    .make-share-button(whatsapp, darken(#43d854, 10%));
    .make-share-button(pinterest, #bd081c);
    .make-share-button(linkedin, #0077b5);
}
