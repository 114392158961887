.jw-btn-wrap {
    display: inline-block;
    position: relative;
}

.jw-btn-container a:hover {
    text-decoration: none !important;
}

.jw-btn {
    background: transparent;
    border: 0;
    cursor: pointer;
    transition: background 200ms ease-out;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:focus,
    &:hover {
        text-decoration: none;
    }

    &:active:not(:disabled) {
        box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
    }

    .jw-spinner {
        margin-left: 0.3em;
    }

    .focus-outline(1px solid, 2px);
}

.jw-btn:disabled {
    opacity: 0.5;
}

.jw-btn-style-border {
    background: transparent;
    text-decoration: none;
    transition: background-color 0.1s linear;

    &:active {
        box-shadow: none;
    }
}

// Size
.jw-btn-sm {
    font-size: 1em;
    padding: 0.3em 0.9em;

    &.jw-btn--icon-only {
        padding: 0.3em 0.4em;
    }
}

.jw-btn-default {
    font-size: 1.3em;
    padding: 0.5em 0.9em;

    &.jw-btn--icon-only {
        padding: 0.5em 0.5em;
    }
}

.jw-btn-lg {
    font-size: 1.8em;
    padding: 0.39em 0.9em;

    &.jw-btn--icon-only {
        padding: 0.39em 0.5em;
    }
}

.jw-btn-is-full-width {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: visible;
}

// backend
.jw-is-backend .jw-button .jw-btn {
    cursor: text;
}

// Make sure to align center the text inside the button. This becomes visible
// ones multiple lines are used.
.jw-btn:not(.jw-btn-is-full-width) {
    @media screen and (min-width: @screen-xs-min) {
        text-align: center;
    }
}

// Align the button itself
.jw-btn-is-left {
    text-align: left;

    .jw-btn-is-full-width {
        justify-content: flex-start;
    }
}

.jw-btn-is-right {
    text-align: right;

    .jw-btn-is-full-width {
        justify-content: flex-end;
    }
}

.jw-btn-is-center {
    text-align: center;

    .jw-btn-is-full-width {
        justify-content: center;
    }
}

// Active state
.jw-btn__active {
    font-weight: bold;
}

.jw-btn-icon {
    margin-right: 0.9em;
    height: 1.2em;
    padding-left: 1.2em;
    position: relative;

    svg {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        path,
        polygon,
        rect {
            fill: currentColor;
        }
    }
}

// Caption
.jw-btn-caption {
    display: inline-block;
    min-width: 1px; // guarantees caret to be shown in edit mode
    // https://stackoverflow.com/a/26347441
}
