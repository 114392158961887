@jw-website-bar-height: 50px;

//button pointing to the website editor when user is logged in
.jw-website-button {
    background: @gray;
    width: 100%;
    height: @jw-website-bar-height;
    padding: 10px;
    text-align: right;
    color: @white;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: normal;
    box-sizing: border-box;

    a {
        display: inline-block;
        margin-left: 15px;
        text-decoration: none;

        .button-size(5px, 9px, 14px, 20px, 2px);
        .button-variant(#fff, #8bc53e, #8bc53e);

        &:hover {
            text-decoration: none;
        }
    }
}

//giving extra space at the bottom of every website so the jw-website-button doesn't overlap the website content
.jw-website-spacer--jump-to-editor {
    height: @jw-website-bar-height;
}

@media (max-width: 450px) {
    .jw-website-button {
        span {
            display: none;
        }

        a {
            text-align: center;
            display: block;
            margin-left: 0;
        }
    }
}
